import React, { useEffect, useRef, useState } from 'react'
import Select from "react-select";
import { useHistory, useParams } from 'react-router-dom';
import { GetAssessmentDetails, GetPracticalAssessmentQuizQuestions, GetPracticalAssessmentStudentListSubmission, GetQuizQuestion , GetQuizQuestionList, lecturerAssessedFinalMarked, selfAssessedFinalMarked, startStopPracticalAssignment } from '../../../../services/CourseService';
import AssessmentDetails from '../../../common/AssessmentDetails';
import PracticalStudentList from '../../../common/PracticalStudentList';
import { useFormik } from 'formik';
import SkeletonTicketList from '../../../../loaders/SkeletonTicketList';
import Swal from 'sweetalert2';



const AssessmentMarking = () => {
  const {id , openTabs , subId , subType, subSubId } = useParams();
  const history = useHistory();
  const [assessmentDetails, setAssessmentDetails] = useState({});
  const [quizQuestions, setQuizQuestions] = useState([]);
  const [quizQuestion, setQuizQuestion] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [criteriaOptions, setCriteriaOptions] = useState([]);
  const [questionLoading, setQuestionLoading]=useState(true)
  const [practicalAssessmentHeader, setPracticalAssessmentHeader] = useState([]);
  const [startStopData, setStartStopData] = useState({});
  const [nextPrevious, setNextPrevious] = useState(false);
  const [studentLoading, setStudentLoading] = useState(false);
  const [pauseSessionLoading, setPauseSessionLoading] = useState(false);
  const prevIdRef = useRef();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      criteria: subSubId || ""
    },
    onSubmit: (values) => {
    }
  });

  let currentIndex = criteriaOptions.findIndex(option => option.value == formik.values.criteria);

  const previousCriteria = () => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      formik.setFieldValue("criteria", criteriaOptions[newIndex].value);
      const newQuestionId = criteriaOptions[newIndex].value;
      setNextPrevious(true);
      setStudentLoading(true);
      history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/${openTabs}/${subId}/questionDetails/${newQuestionId}`);
    } else {
      console.log("No previous criteria available.");
    }
  }

  const nextCriteria = () => {
    if (currentIndex < criteriaOptions.length - 1) {
      const newIndex = currentIndex + 1;
      formik.setFieldValue("criteria", criteriaOptions[newIndex].value);
      const newQuestionId = criteriaOptions[newIndex].value;

      setNextPrevious(true);
      setStudentLoading(true);
      history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/${openTabs}/${subId}/questionDetails/${newQuestionId}`);
    } else {
      console.log("No next criteria available.");
    }
  }

  const handleGoToCriteriaList = () => {
    fetchQuizQuestions();
    history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/${openTabs}/${subId}`);
  }

  const fetchAssessmentDetails = () => {
    GetAssessmentDetails(subId)
      .then((response) => {
        setAssessmentDetails(response?.data);
      })
      .catch((err) => console.log(err))
  }

  const handleFinalizeMarking = () => {
    if(openTabs == "assessmentMarking"){
      lecturerAssessedFinalMarked({assessment_id: parseInt(subId)})
      .then((res) => {
        Swal.fire({
          title: "Assessment Marking Finalized",
          text: "Assessment Marking has been finalized successfully",
          icon: "success",
          button: "OK",
        });
        fetchAssessmentDetails();
      })
        .catch((err) => {
          console.log("err", err);
          Swal.fire({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "OK",
          });
        })
    } else if(openTabs == "assessmentMarkingReview"){
      selfAssessedFinalMarked({assessment_id: parseInt(subId)})
      .then((res) => {
        Swal.fire({
          title: "Assessment Marking Finalized",
          text: "Assessment Marking has been finalized successfully",
          icon: "success",
          button: "OK",
        });
        fetchAssessmentDetails();
      })
        .catch((err) => {
          console.log("err", err);
          Swal.fire({
            title: "Error",
            text: "Something went wrong",
            icon: "error",
            button: "OK",
          });
        })
    }
  }

  useEffect(() => {
    fetchAssessmentDetails();
  }, []);

  const fetchQuizQuestions = () => {
    setQuestionLoading(true)
    const data = {
      assessment_id: subId,
      quizId: assessmentDetails.quiz_id,
      is_self_assessed: openTabs == "assessmentMarking" ? 0 : 1
    }

    GetPracticalAssessmentQuizQuestions(data)
      .then((res) => {
        setQuizQuestions(res?.data?.res);
        setPracticalAssessmentHeader(res?.data?.header);
        setQuestionLoading(false)
      })
      .catch((err) => {
        console.log(err)
        setQuestionLoading(false)
      })
  }

  useEffect(() => {
    if (assessmentDetails?.quiz_id) {
      fetchQuizQuestions();
    }
  }, [assessmentDetails]);

  const fetchQuizQuestionDetails = () => {
    const data = {
      assessment_id: parseInt(subId),
      quizId: assessmentDetails.quiz_id,
      questionId: parseInt(subSubId),
      is_self_assessed: openTabs == "assessmentMarking" ? 0 : 1
    }

    GetQuizQuestion(data)
      .then((res) => {
        setQuizQuestion(res?.data?.res);
      })
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    if (assessmentDetails?.quiz_id && subSubId) {
      fetchQuizQuestionDetails();
    }
  }, [subSubId , assessmentDetails]);

  const fetchQuizQuestion = () => {
    if (openTabs == "assessmentMarking") {
        setLoading(true);
        const data = {
          assessment_id:assessmentDetails.id,
          intake_id:assessmentDetails.intake,
          question_id: parseInt(subSubId),
          is_self_assessed: 0
        }
  
        GetPracticalAssessmentStudentListSubmission(data)
          .then((res) => {
            setStudentList(res?.data?.list);
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
    } else {
        setLoading(true);
        const data = {
          assessment_id:assessmentDetails.id,
          intake_id:assessmentDetails.intake,
          question_id: parseInt(subSubId),
          is_self_assessed: 1
        }
  
        GetPracticalAssessmentStudentListSubmission(data)
          .then((res) => {
            setStudentList(res?.data?.list);
            setTotalRows(res?.data?.total);
          })
          .catch((err) => console.log(err))
          .finally(() => setLoading(false));
    }
  }

  useEffect(() => {
    if (assessmentDetails?.id && assessmentDetails?.intake && subSubId) {
      fetchQuizQuestion();
    }
  }, [assessmentDetails , subSubId , openTabs]);

  const handlePauseSession = () => {
    setPauseSessionLoading(true);
    const data = {
      assessment_id: parseInt(subId),
      action: "stop"
    }

    startStopPracticalAssignment(data).then((res) => {
      // if(res?.data?.message == "Assessment session stopped successfully"){
        history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}`);
      // }
    }).catch((err) => {
      console.log("err" , err);
    }).finally(() => {
      setPauseSessionLoading(false);
    })
  }

  useEffect(() => {
  const fetchCriteriaOptions = () => {
    GetQuizQuestionList(assessmentDetails.quiz_id , subId)
      .then((res) => {
        setCriteriaOptions(res?.data?.data);
        setStartStopData(res?.data?.assessmentDetails);
      })
      .catch((err) => console.log(err))
  }

    if (assessmentDetails?.quiz_id && subId) {
      fetchCriteriaOptions();
    }
  }, [assessmentDetails , subId]);

  useEffect(() => {
    currentIndex = criteriaOptions.findIndex(option => option.value == formik.values.criteria);
  }, [formik.values.criteria, criteriaOptions]);

  useEffect(() => {
    if(openTabs == "assessmentMarking" && startStopData?.is_start_assessment == 0){
      history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/submissionsAndResults/${subId}`);
    }
  }, [startStopData]);

  useEffect(() => {
      prevIdRef.current = subSubId;
  }, [subSubId]);

  return (
    <>
      {/* {subType == "questionDetails" && openTabs == "assessmentMarking" && <div className='card-new card card-profile-info-card' style={{ marginBottom: 20 }}>
        <div className='row align-items-center'>
          <div className="col-md-6 col-lg-4">
            <div className='row'>
              <div className='col-lg-12'>
                <div className="form-group-blk mb-3">
                  <label htmlFor="AssessmentType" title='Criteria'>Criteria</label>
                  <Select
                  className={
                    "form-control custom-select-box" +
                    (formik.errors.criteria && formik.touched.criteria
                        ? " is-invalid"
                        : "")
                    }
                    name="criteria"
                    id="criteria"
                    value={criteriaOptions.find(val => val.value == formik.values.criteria)}
                    onChange={(option) => {
                        formik.setFieldValue("criteria", option ? option.value : "");
                        history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/assessmentMarkingReview/${subId}/questionDetails/${option ? option.value : ""}`);
                    }}
                    onBlur={formik.handleBlur}
                    options={criteriaOptions}
                    maxMenuHeight={175}
                    placeholder={"Criteria"}
                    isClearable
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 ml-auto'>
            <div className='d-flex justify-content-end'>
              <button type="button" className="btn btn-save btn-success mr-3" title="Pause Session" onClick={handlePauseSession}>
                <i className="fal fa-times"></i>Pause Session
              </button>
              <button type="button" className="btn btn-save btn-success mr-3" title="Go to Previous Page" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/${openTabs}/${subId}`)} >
                <i className="fal fa-clipboard-check"></i>Go to Previous Page
              </button>
              <button
                type="button"
                className="btn btn-save btn-success mr-3"
                title="Previous Criteria"
                onClick={previousCriteria}
                disabled={criteriaOptions.findIndex(option => option.value == formik.values.criteria) === 0}
              >
                <i className="fal fa-chevron-left"></i>Previous Criteria
              </button>

              <button
                type="button"
                className="btn btn-save btn-success"
                title="Next Criteria"
                onClick={nextCriteria}
                disabled={criteriaOptions.findIndex(option => option.value == formik.values.criteria) === criteriaOptions.length - 1}
              >
                Next Criteria <i className="fal fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div >} */}

      {subType == undefined && questionLoading && !quizQuestions?.length ? <SkeletonTicketList/> : <AssessmentDetails quizQuestion={quizQuestion} practicalAssessmentHeader={practicalAssessmentHeader} startStopData={startStopData} handlePauseSession={handlePauseSession} />}
      {subType == undefined && questionLoading && !quizQuestions?.length && <SkeletonTicketList/>}
      {subType == undefined && quizQuestions && quizQuestions.length > 0 && <div className="card-new card card-profile-info-card mb-30">
        <div className="">
          {/* <div className="card-header">{`${headerData?.ass_type == 13 || headerData?.ass_type == 14 ? "Assessment" : "Marking"} Management`} {loading && <i className="fas fa-cog fa-spin ml-2"></i>}</div> */}
          <div className="card-header" title="Assessment Criteria">Assessment Criteria</div>
          {quizQuestions.map((item, index) => (
            <div className="card-body-inr card-body-info" style={{ border: '1px solid #D7DEE8', padding: "7px 10px", marginBottom: 20 }} key={index}>
              <div className="basic-info-box">
                <div className="row align-items-center">
                  <div className='col-12 text-sm'>
                    <h6 className='criteria' title={item?.question_name}>{item?.question_name}</h6>
                  </div>

                  <div className="col-md-1 mb-md-0 mb-3">
                    <h6 className='mb-3 fontsize-14 fw-bolds' title="Score">Score</h6>
                    <p className='fontsize-16 mb-0' title={item?.correct_score}>{item?.correct_score}</p>
                  </div>

                  <div className='col-md-4 mb-md-0 mb-3'>
                    <h6 className='mb-3 fontsize-14 fw-bolds' title="Marking Status">Marking Status</h6>
                    <div className='d-flex'>
                      {item?.marked ? <div className='mr-3'>
                        <span title={`Marked: ${item?.marked}`}><span style={{fontWeight: 600}}>Marked&nbsp;:</span>&nbsp;{item?.marked}</span>
                        </div> : <div className='mr-3' title={`Marked: 0`}><span style={{fontWeight: 600}}>Marked&nbsp;:</span>&nbsp;0</div>}
                      {item?.not_marked ? <div className='mr-3'>
                        <span title={`Not Yet Marked: ${item?.not_marked}`}><span style={{fontWeight: 600}}>Not Yet Marked&nbsp;:</span>&nbsp;{item?.not_marked}</span>
                      </div> : <div className='mr-3' title={`Not Yet Marked: 0`}><span style={{fontWeight: 600}}>Not Yet Marked&nbsp;:</span>&nbsp;0</div>}
                      {item?.absent ? <div>
                        <span title={`Marked Absent: ${item?.absent}`}><span style={{fontWeight: 600}}>Marked Absent&nbsp;:</span>&nbsp;{item?.absent}</span>
                      </div> : <div title={`Marked Absent: 0`}><span style={{fontWeight: 600}}>Marked Absent&nbsp;:</span>&nbsp;0</div>}
                    </div>
                  </div>

                  {(item?.evidence_photo == 1 || item?.evidence_video == 1 || item?.evidence_document == 1 || item?.evidence_lecturer_observation == 1) && <div className='col-md-5 mb-md-0 mb-3'>
                    <h6 className='mb-3 fontsize-14 fw-bolds' title="Evidence Required">Evidence Required</h6>
                    <div className='d-flex'>
                      {item?.evidence_photo == 1 && <div className='mr-3' title="Photo">
                        <span><i className="fal fa-camera"></i> Photo</span>
                      </div>}
                      {item?.evidence_video == 1 && <div className='mr-3' title="Video">
                        <span><i className="fal fa-video"></i> Video</span>
                      </div>}
                      {item?.evidence_document == 1 && <div className='mr-3' title="Document">
                        <span><i className="fal fa-file-alt"></i> Document</span>
                      </div>}
                      {item?.evidence_lecturer_observation == 1 && <div className='lecturer-observation-question' title="Lecturer Observation">
                        <span><i className="fal fa-user-check"></i> Lecturer Observation</span>
                      </div>}
                    </div>
                  </div>}

                  <div className="col-md-2 ml-auto align-self-start text-end">
                    <button type="button" className="btn btn-save btn-success" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/${openTabs}/${subId}/questionDetails/${item?.qid}`)} title={openTabs == "assessmentMarking" ? "Mark Criteria" : "Mark Submissions"}>
                      <i className="fal fa-pen"></i>{openTabs == "assessmentMarking" ? "Mark Criteria" : "Mark Submissions"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}

            <div className='d-flex flex-wrap'>
              {/* <button type="submit" className="btn btn-save btn-success mr-3 mb-3 mb-md-0" title="Save">
                <i className="fal fa-save"></i>Save
              </button> */}
              {openTabs == "assessmentMarking" && <button type="button" className="btn btn-save btn-success mr-3 mb-3 mb-md-0" onClick={handlePauseSession} title="Pause Assessment">
                <i className="fal fa-pen"></i>Pause Assessment
              </button>}
              {/* disabled={openTabs == "assessmentMarking" ? assessmentDetails?.lecturer_assessed_finalize_mark == 1 : assessmentDetails?.self_assessed_finalize_mark == 1} */}
              <button type="button" className="btn btn-save btn-success mr-3" title="Finalize Marking" onClick={handleFinalizeMarking}>
                <i className="fal fa-clipboard-check"></i>Finalize Marking
              </button>
              {/* <button type="reset" className="btn btn-close btn-danger" title="Cancel">
                <i className="fal fa-times"></i>Cancel
              </button> */}
            </div>
        </div>
      </div >}

      <PracticalStudentList quizQuestion={quizQuestion} studentList={studentList} totalRows={totalRows} loading={loading} setLoading={setLoading} fetchQuizQuestion={fetchQuizQuestion} nextPrevious={nextPrevious} setStudentLoading={setStudentLoading} fetchQuizQuestionDetails={fetchQuizQuestionDetails} prevIdRef={prevIdRef.current} />

      {subType == "questionDetails" && <div className='sticky-save-footer'>
        <div className='row align-items-center'>
          <div className="col-md-6 col-lg-4">
            <div className='row'>
              <div className='col-lg-12'>
                <div className="form-group-blk mb-3">
                  <label htmlFor="AssessmentType" title='Criteria'>Criteria</label>
                  <Select
                  className={
                    "form-control custom-select-box" +
                    (formik.errors.criteria && formik.touched.criteria
                        ? " is-invalid"
                        : "")
                    }
                    name="criteria"
                    id="criteria"
                    value={criteriaOptions.find(val => val.value == formik.values.criteria)}
                    onChange={(option) => {
                        formik.setFieldValue("criteria", option ? option.value : "");
                        history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/assessmentMarking/${subId}/questionDetails/${option ? option.value : ""}`);
                    }}
                    onBlur={formik.handleBlur}
                    options={criteriaOptions}
                    maxMenuHeight={175}
                    placeholder={"Criteria"}
                    isClearable
                    menuPlacement="top"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6 col-lg-8 ml-auto'>
            <div className='d-flex justify-content-end'>
              {openTabs == "assessmentMarking" && <button type="button" className="btn btn-save btn-success mr-3" title="Pause Assessment" onClick={handlePauseSession} disabled={pauseSessionLoading}>
                {pauseSessionLoading ? <i className="fas fa-cog fa-spin"></i> : <i className="fal fa-times"></i>} Pause Assessment
              </button>}
              <button type="button" className="btn btn-save btn-success mr-3" title="Go to Criteria List" onClick={() => handleGoToCriteriaList()} >
                <i className="fal fa-clipboard-check"></i>Go to Criteria List
              </button>
              <button
                type="button"
                className="btn btn-save btn-success mr-3"
                title="Previous Criteria"
                onClick={previousCriteria}
                disabled={(criteriaOptions.findIndex(option => option.value == formik.values.criteria) === 0) || studentLoading}
              >
                <i className="fal fa-chevron-left"></i>Previous Criteria
              </button>

              <button
                type="button"
                className="btn btn-save btn-success"
                title="Next Criteria"
                onClick={nextCriteria}
                disabled={(criteriaOptions.findIndex(option => option.value == formik.values.criteria) === criteriaOptions.length - 1) || studentLoading}
              >
                Next Criteria <i className="fal fa-chevron-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div >}
    </>
  )
}

export default AssessmentMarking