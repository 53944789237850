import React, { useEffect, useState } from 'react';
import Header from '../common/Header';
import SideBar from '../common/SideBar';
// import Tabs from './Tabs';
// import { getCurrentTimeHomePage } from '../common/Helper';
import Dashboard from './DetailCoursesBlocks/DashBoard';
import CourseDetails from './DetailCoursesBlocks/CourseDetails';
import Assessments from './DetailCoursesBlocks/Assessments';
import Assemblies from './DetailCoursesBlocks/Assemblies';
import OnlineClasses from './DetailCoursesBlocks/OnlineClasses';
import Notifications from './DetailCoursesBlocks/Notifications';
import Students from './DetailCoursesBlocks/Students';
import Tickets from './DetailCoursesBlocks/Tickets';
// import AuditTrail from "./DetailCoursesBlocks/AuditTrail";
import AuditTrail from './AuditTrail';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  GetAssessmentDetails,
  GetOnlineClassGraphData,
  GetCourseDetails,
} from '../../services/CourseService';
import { TrimText } from '../common/TrimText';
import PostsAndFeed from './DetailCoursesBlocks/PostsAndFeed';
import PermissionsGate from '../../utils/permissionGate';
import { GlobalIconNames } from '../../utils/GlobalIconNames';
import Programs from './DetailCoursesBlocks/Programs';
import { SP_PASS, STUDENT_PORTAL_URL } from '../../utils/Constants';
import { GetSubjectContentService } from '../../services/PostsAndFeedService';
import { useSelector } from 'react-redux';
import AssembliesDrag from './DetailCoursesBlocks/AssembliesDrag';
import CourseGradeSettingsList from './CourseGradeSettings/CourseGradeSettingsList';
import CourseGradeBook from './CourseGradbook/CourseGradeBook';
import CertificateLists from '../StudentAdministration/CertificateTemplates/Certificate Tabs/CertificatesList';

const DetailCourses = () => {
  const { id, tab, type, subId , openTabs , subType } = useParams();
  const history = useHistory();
  const [basicInfo, setBasicInfo] = useState({});
  const [unreadCommentCount, setUnreadCommentCount] = useState(0);
  const bottomScroll = React.createRef();
  const user = useSelector((state) => state.user);
  const assessment_name = useSelector(
    (state) => state.assessment.assessment_name
  );
  const [updateCertificateTemplate, setUpdateCertificateTemplate] =
    useState(false);
  const [subjectContent, setSubjectContent] = useState([]);
  const [chatUnreadCount, setChatUnreadCount] = useState(0);
  const [assName, setAssName] = useState('');
  const [courseName, setCourseName] = useState('');
  const [courseId, setCourseId] = useState();
  const [enableChat, setEnableChat] = useState(false);
  const [courseData, setCourseData] = useState('');
  const [enableCertificate, setEnableCertificate] = useState(0);
  const [certificateTemplateData, setCertificateTemplateData] = useState({
    certificateTemplateId: '',
    courseId: '',
    certificateName: ' ',
  });
  const [gradingStandard, setGradingStandard] = useState()
  const [saveData, setSaveData] = useState(false)
  const [courseDetails, setCourseDetails] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
      getFirstGraphData();
  }, [])
  
  const getFirstGraphData = () => {
    setLoading(true);
    GetOnlineClassGraphData(id)
      .then((response) => {
        setBasicInfo(response && response.data ? response.data : {});
      })
      .catch((error) => {})
      .finally(()=>{setLoading(false);});
  };

  useEffect(() => {
    GetSubjectContentService({ sid: id, user_id: user.id })
      .then((res) => {
        const certificateId = res.data.subject?.cert_template_id;
        const courseId = res.data.subject?.id;
        const certificateName = res.data.subject?.certificate_name;
        setCertificateTemplateData({
          certificateTemplateId: certificateId,
          courseId: courseId,
          certificateName: certificateName,
        });
        // setCertificateTemplateData(res.data?.subject?.cert_template_id);
        setSubjectContent(res.data);
        setUnreadCommentCount(res.data?.messageCount);
        setChatUnreadCount(res.data?.messageCount);
        setCourseName(res.data?.subject?.name);
        setCourseId(id);
        setEnableCertificate(res.data?.subject.enable_certificate);
      })
      .catch((error) => {});
  }, [enableChat, enableCertificate, updateCertificateTemplate]);
  useEffect(() => {
    GetCourseDetails(id)
      .then((data) => {
        setCourseData(data.data.correspondence);
      })
      .catch((error) => {
        console.log('error ====> ', error);
      });
    if (subId) {
      GetAssessmentDetails(subId)
        .then((res) => {
          setAssName(res.data.name);
        })
        .catch((err) => console.log(err));
    }
  }, [id, subId]);





    useEffect(() => {
        GetSubjectContentService({ sid: id, user_id: user.id })
            .then((res) => {
                setSubjectContent(res.data)
                setUnreadCommentCount(res.data.messageCount)
                setChatUnreadCount(res.data.messageCount)
                setCourseName(res.data?.subject?.name)
            })
            .catch((error) => { });
    }, [enableChat])
    useEffect(() => {
        GetCourseDetails(id)
        .then((data) => {
          setCourseDetails(data.data)
          if(data.data.grading_standard) { 
          setGradingStandard(data.data.grading_standard)
          }
          setCourseData(data.data.correspondence)
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
        if(subId){
            GetAssessmentDetails(subId).then((res)=>{
                setAssName(res.data.name)
            }).catch(err=>console.log(err))
        }
    }, [id, subId, saveData])
    
    return (
        <>
            <Header />
            <div className="content-wrapper d-flex flex-wrap">
                <SideBar />
                <div className={"sidebar-right " + (tab === "gradeBooks" ? "bottom-100" : "") + ((openTabs === "assessmentMarkingReview" || openTabs === "assessmentMarking") && subType === "questionDetails" ? "bottom-120" : "")} >
                    <div className="sub-menu-content-block">
                        {/* <Tabs /> */}
                        <div className="sub-menu-right-block space-box">
                            <div className="wrap-box-right">
                                <div className="my-tickets-heading-blk flex-direction mb-2 shift-move">
                                    <div className="d-flex align-items-center ">
                                        <div className="name-w-head d-flex align-items-center ">
                                            {tab == "assessments" && type == "open" ? <h4 className="text-left-align landing-heading heading_color_dynamic">Assessment:<span className="ml-1" title={assName}>{TrimText(assName, 60)}</span></h4> :<h4 className="text-left-align landing-heading heading_color_dynamic">Courses:<span className="ml-1" title={basicInfo?.course_name}>{TrimText(basicInfo?.course_name, 60)}</span></h4>}
                                        </div>
                                        <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <button onClick={() => history.goBack()} title="Back" className="btn btn-white-bordered">
                                                        {GlobalIconNames("leftarrow")}Back
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        {tab == "assessments" && type == "open" ? <div className="text-md-right action2-blk">
                                            <div className="ticket-view-btn d-flex align-items-center">
                                                <div className="add-new-ticket-bx">
                                                    <Link
                                                        title="Course"
                                                        className="btn btn-white-bordered"
                                                        to={`/courseAdministration/coursesdetails/${id}/detailCourses/show`}
                                                        // target="_blank"
                                                    >
                                                        <i className="fal fa-book"></i>Course
                                                    </Link>
                                                </div>
                                            </div>
                                        </div> : 
                                        <div className="text-md-right action2-blk">
                                        <div className="ticket-view-btn d-flex align-items-center">
                                            <div className="add-new-ticket-bx">                                                
                                              {loading ? 
                                                <button disabled className="btn btn-white-bordered" title="Student View">
                                                  <i className="fas fa-cog fa-spin"></i> Student View 
                                                </button>
                                              :<a
                                                title="Student View"
                                                className="btn btn-white-bordered"
                                                href={(basicInfo.qualification_id==null || basicInfo.qualification_id==undefined || basicInfo.qualification_id=="") ? `${STUDENT_PORTAL_URL}/courseinfo/shortcourse/${basicInfo.intakeID}/${SP_PASS}` : `${STUDENT_PORTAL_URL}/courseinfo/${basicInfo.qualification_id}/subject/${basicInfo.intakeID}/${SP_PASS}`}                                                                                                    
                                                target='_blank'
                                              >
                                                {GlobalIconNames("studentview").html}
                                              </a>}
                                            </div>
                                        </div>
                                    </div>}
                                    </div>                                    
                </div>
                <div className="tabs-wrap">
                  {((tab == 'assessments' && type != 'open') ||
                    (tab != 'assessments' && type != 'open')) && (
                    <div className="scroll-tabs filter-scroll c-o-c-a coures-scroll-arrow">
                      <button
                        type="button"
                        className="btn btn-arrow btn-scroll-left"
                        onClick={() =>
                          (bottomScroll.current.scrollLeft =
                            bottomScroll.current.scrollLeft - 40)
                        }
                      >
                        <i className="fal fa-chevron-left"></i>
                      </button>
                      <div
                        className={`filter-scroll-inner `}
                        ref={bottomScroll}
                      >
                        <ul
                          className="nav nav-pills"
                          id="tabs"
                          role="tablist"
                        >
                          <PermissionsGate scopes={['cdaview']}>
                            <li
                              className="nav-item"
                              onClick={() =>
                                history.push(
                                  `/courseAdministration/coursesdetails/${id}/dashboard/show`
                                )
                              }
                            >
                              <a
                                className={`nav-link cursor-pointer ${
                                  tab === 'dashboard' ? 'active' : ''
                                }`}
                              >
                                <i className="fal fa-chart-pie-alt"></i>
                                Dashboard
                              </a>
                            </li>
                          </PermissionsGate>

                          <PermissionsGate scopes={['cedit']}>
                            <li
                              className="nav-item"
                              onClick={() =>
                                history.push(
                                  `/courseAdministration/coursesdetails/${id}/detailCourses/show`
                                )
                              }
                            >
                              <a
                                className={`nav-link cursor-pointer ${
                                  tab === 'detailCourses' ? 'active' : ''
                                }`}
                              >
                                {GlobalIconNames('details').html}
                              </a>
                            </li>
                          </PermissionsGate>

                          <PermissionsGate scopes={['cedit']}>
                            <li
                              className="nav-item"
                              onClick={() =>
                                history.push(
                                  `/courseAdministration/coursesdetails/${id}/assignedProgram/show`
                                )
                              }
                            >
                              <a
                                className={`nav-link cursor-pointer ${
                                  tab === 'assignedProgram' ? 'active' : ''
                                }`}
                              >
                                {GlobalIconNames('assignedprogrammes').html}
                              </a>
                            </li>
                          </PermissionsGate>

                          <PermissionsGate scopes={['castview']}>
                            <li
                              className="nav-item"
                              onClick={() =>
                                history.push(
                                  `/courseAdministration/coursesdetails/${id}/assessments/show`
                                )
                              }
                            >
                              <a
                                className={`nav-link cursor-pointer ${
                                  tab === 'assessments' ? 'active' : ''
                                }`}
                              >
                                <i className="fal fa-file-alt"></i>Assessments
                              </a>
                            </li>
                          </PermissionsGate>

                          {/* <PermissionsGate scopes={['cagsview']}>
                            <li
                              className="nav-item"
                              onClick={() =>
                                history.push(
                                  `/courseAdministration/coursesdetails/${id}/gradeSettings/show`
                                )
                              }
                            >
                              <a
                                className={`nav-link cursor-pointer ${
                                  tab === 'gradeSettings' ? 'active' : ''
                                }`}
                              >
                                <i className="fal fa-sliders-h-square"></i>Grade
                                Settings
                              </a>
                            </li>
                          </PermissionsGate> */}

                          {enableCertificate === 1 && (
                            <PermissionsGate scopes={['ccerteficateview']}>
                              <li
                                className="nav-item"
                                onClick={() =>
                                  history.push(
                                    `/courseAdministration/coursesdetails/${id}/certificateLists/show`
                                  )
                                }
                              >
                                <a
                                  className={`nav-link cursor-pointer ${
                                    tab === 'certificateLists' ? 'active' : ''
                                  }`}
                                >
                                  <i className="fal fa-file-certificate"></i>
                                  Certificates
                                </a>
                              </li>
                            </PermissionsGate>
                          )}

                                                { gradingStandard != 2 && <PermissionsGate scopes={["cagsview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/gradeSettings/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "gradeSettings" ? "active" : ""}`}>
                                                        <i className="fal fa-sliders-h-square"></i>Grade Settings
                                                        </a>
                                                    </li>
                                                </PermissionsGate> }

                                                { gradingStandard != 2 && <PermissionsGate scopes={["cagbview"]}>
                                                    <li className="nav-item" onClick={() => history.push(`/courseAdministration/coursesdetails/${id}/gradeBooks/show`)}>
                                                        <a className={`nav-link cursor-pointer ${tab === "gradeBooks" ? "active" : ""}`} >
                                                        <i className="fal fa-bookmark"></i>Gradebook
                                                        </a>
                                                    </li>
                                                </PermissionsGate> }

                          <PermissionsGate scopes={['casbview']}>
                            <li
                              className="nav-item"
                              onClick={() =>
                                history.push(
                                  `/courseAdministration/coursesdetails/${id}/assemblies/show`
                                )
                              }
                            >
                              <a
                                className={`nav-link cursor-pointer ${
                                  tab === 'assemblies' ||
                                  tab === 'assembliesDrag'
                                    ? 'active'
                                    : ''
                                }`}
                              >
                                <i className="fal fa-info-circle"></i>Content
                              </a>
                            </li>
                          </PermissionsGate>
                          {courseData !== 'selfplaced' ? (
                            <PermissionsGate scopes={['cocview']}>
                              <li
                                className="nav-item"
                                onClick={() =>
                                  history.push(
                                    `/courseAdministration/coursesdetails/${id}/onlineClasses/show`
                                  )
                                }
                              >
                                <a
                                  className={`nav-link cursor-pointer ${
                                    tab === 'onlineClasses' ? 'active' : ''
                                  }`}
                                >
                                  <i className="fal fa-laptop"></i>Class
                                  Schedule
                                </a>
                              </li>
                            </PermissionsGate>
                          ) : null}
                          <PermissionsGate scopes={['cnoview']}>
                            <li
                              className="nav-item"
                              onClick={() =>
                                history.push(
                                  `/courseAdministration/coursesdetails/${id}/notifications/show`
                                )
                              }
                            >
                              <a
                                className={`nav-link cursor-pointer ${
                                  tab === 'notifications' ? 'active' : ''
                                }`}
                              >
                                {GlobalIconNames('notifications').html}
                              </a>
                            </li>
                          </PermissionsGate>

                          <PermissionsGate scopes={['cstview']}>
                            <li
                              className="nav-item"
                              onClick={() => {
                                // const state = {
                                //   certificateTemplateId: certificateTemplateId,
                                // };
                                // history.push(
                                //   `/courseAdministration/coursesdetails/${id}/students/show`,
                                //   state
                                // );
                                history.push({
                                  pathname: `/courseAdministration/coursesdetails/${id}/students/show`,
                                  state: {
                                    certificateTemplateData:
                                      certificateTemplateData,
                                  },
                                });
                              }}
                            >
                              <a
                                className={`nav-link cursor-pointer ${
                                  tab === 'students' ? 'active' : ''
                                }`}
                              >
                                <i className="fal fa-users"></i>Students
                              </a>
                            </li>
                          </PermissionsGate>

                          <PermissionsGate scopes={['ctiview']}>
                            <li
                              className="nav-item"
                              onClick={() =>
                                history.push(
                                  `/courseAdministration/coursesdetails/${id}/tickets/show`
                                )
                              }
                            >
                              <a
                                className={`nav-link cursor-pointer ${
                                  tab === 'tickets' ? 'active' : ''
                                }`}
                              >
                                <i className="fal fa-ticket"></i>Tickets
                              </a>
                            </li>
                          </PermissionsGate>
                          {courseData !== 'selfplaced' &&
                          subjectContent?.subject?.convChatGroup == '1' ? (
                            <PermissionsGate scopes={['cpfview']}>
                              <li
                                className="nav-item"
                                onClick={() =>
                                  history.push(
                                    `/courseAdministration/coursesdetails/${id}/post-feed/show`
                                  )
                                }
                              >
                                <a
                                  className={`nav-link cursor-pointer ${
                                    (tab === 'post-feed' || tab === "shared-files") ? 'active' : ''
                                  }`}
                                >
                                  {unreadCommentCount > 0 ? (
                                    <small className="notify-count1">
                                      {unreadCommentCount < 10
                                        ? unreadCommentCount
                                        : '9+'}
                                    </small>
                                  ) : null}
                                  <i className="fal fa-comments"></i>News Room
                                </a>
                              </li>
                            </PermissionsGate>
                          ) : null}
                          <li
                            className="nav-item"
                            onClick={() =>
                              history.push(
                                `/courseAdministration/coursesdetails/${id}/auditTrail/show`
                              )
                            }
                          >
                            <a
                              className={`nav-link cursor-pointer ${
                                tab === 'auditTrail' ? 'active' : ''
                              }`}
                            >
                              {GlobalIconNames('audittrail').html}
                            </a>
                          </li>
                        </ul>
                      </div>
                      <button
                        type="button"
                        className="btn btn-arrow btn-scroll-right"
                        onClick={() =>
                          (bottomScroll.current.scrollLeft =
                            bottomScroll.current.scrollLeft + 40)
                        }
                      >
                        <i className="fal fa-chevron-right"></i>
                      </button>
                    </div>
                  )}
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab11"
                      role="tabpanel"
                      aria-labelledby="pills-tab1"
                    >
                      {tab === 'dashboard' && <Dashboard userId={id} />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab12"
                      role="tabpanel"
                      aria-labelledby="pills-tab2"
                    >
                      {tab === 'detailCourses' && (
                        <CourseDetails
                          userId={id}
                          setEnableChat={setEnableChat}
                          enableChat={enableChat}
                          setEnableCertificate={setEnableCertificate}
                          enableCertificate={enableCertificate}
                          setSaveData={setSaveData}
                        />
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab21"
                      role="tabpanel"
                      aria-labelledby="pills-tab10"
                    >
                      {tab === 'assignedProgram' && <Programs userId={id} gradingStandard={gradingStandard} setSaveData={setSaveData} saveData={saveData} />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab13"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {tab === 'assessments' && (
                        <Assessments
                          userId={id}
                          assName={assName}
                          lmType={courseData}
                          gradingStandard={gradingStandard}
                        />
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab51"
                      role="tabpanel"
                      aria-labelledby="pills-tab52"
                    >
                      {tab === 'gradeSettings' && (
                        <CourseGradeSettingsList
                          userId={id}
                          assName={assName} 
                          gradingStandard={gradingStandard}
                        />
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab52"
                      role="tabpanel"
                      aria-labelledby="pills-tab53"
                    >
                      {tab === 'certificateLists' && (
                        <CertificateLists
                          courseName={courseName}
                          courseId={courseId}
                          certificateTemplateData={certificateTemplateData}
                          setUpdateCertificateTemplate={
                            setUpdateCertificateTemplate
                          }
                          updateCertificateTemplate={updateCertificateTemplate}
                        />
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab78"
                      role="tabpanel"
                      aria-labelledby="pills-tab79"
                    >
                      {tab === 'gradeBooks' && (
                        <CourseGradeBook
                          userId={id}
                          assName={assName}
                          courseName={courseName}
                          gradingStandard={gradingStandard}
                        />
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab14"
                      role="tabpanel"
                      aria-labelledby="pills-tab3"
                    >
                      {tab === 'assemblies' && <Assemblies userId={id} />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab15"
                      role="tabpanel"
                      aria-labelledby="pills-tab4"
                    >
                      {tab === 'onlineClasses' && <OnlineClasses userId={id} />}
                    </div>
                    {/* <div className="tab-pane fade active show" id="pills-tab16" role="tabpanel" aria-labelledby="pills-tab5">
                                            {tab === "recordings" && <Recordings userId={id} />}
                                        </div> */}
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab17"
                      role="tabpanel"
                      aria-labelledby="pills-tab6"
                    >
                      {tab === 'notifications' && <Notifications userId={id} />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab18"
                      role="tabpanel"
                      aria-labelledby="pills-tab7"
                    >
                      {tab === 'students' && courseName ? (
                        <Students userId={id} courseName={courseName} courseDetails={courseDetails} />
                      ) : (
                        ''
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab19"
                      role="tabpanel"
                      aria-labelledby="pills-tab8"
                    >
                      {tab === 'tickets' && <Tickets userId={id} />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab19"
                      role="tabpanel"
                      aria-labelledby="pills-tab8"
                    >
                      {(tab === 'post-feed' || tab === "shared-files") && (
                        <PostsAndFeed
                          userId={id}
                          user_id={user.UserID}
                          unreadCommentCount={unreadCommentCount}
                          setUnreadCommentCount={setUnreadCommentCount}
                          subjectContent={subjectContent}
                          chatUnreadCount={chatUnreadCount}
                          setChatUnreadCount={setChatUnreadCount}
                        />
                      )}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab20"
                      role="tabpanel"
                      aria-labelledby="pills-tab9"
                    >
                      {tab === 'auditTrail' && <AuditTrail userId={id} />}
                    </div>
                    <div
                      className="tab-pane fade active show"
                      id="pills-tab21"
                      role="tabpanel"
                      aria-labelledby="pills-tab21"
                    >
                      {tab === 'assembliesDrag' && <AssembliesDrag />}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailCourses;
