import React, { useMemo, useState, useEffect } from "react";
import moment from "moment";
import DataTable from "react-data-table-component";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import JsPDF from "jspdf";
import "jspdf-autotable";
import Swal from "sweetalert2";
import ClassRecordingModal from "./ClassRecordingModal";
import ClassRecordingForm from "./ClassRecordingForm";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import {
  classLaunch,
  DeleteOnlineClass,
  getAve,
  GetLuanchButtonData,
  JoinClassAsAdmin,
  LaunchVirtualClass,
  SendQuickNotification,
  UpdateClassRecordStatus,
} from "../../../../services/CourseService";
import { Link, useParams, useHistory } from "react-router-dom";
import HtmlInputEditor from "../../../common/HtmlInputEditor";
import $ from "jquery";
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE, TABLE_TIME_FORMAT } from "../../../../utils/Constants";
import PermissionsGate from "../../../../utils/permissionGate";
import {
  GetListOfDashboard,
  GetLearningDashboardURL,
  GetVritualClassDropdown,
  getClassFiles,
} from "../../../../services/DashBoardServices";
import { useFormik } from "formik";
import * as Yup from "yup";
import { formatQuestion } from "../../../../utils/FormatQuestion";
import OnlineClassType from "../../../common/OnlineClassType";
import Tablefilter from "../../../common/Tablefilter";
import { RenderClassStatus, RenderClassType } from "../../../../utils/CommonStatusItems";
import CopyToClipboard from "../../../CopyToClipboard";
import { RenderLearningMethod } from "../../../../utils/CommonGroupingItem";
import ClassSeriesPopup from "./ClassSeriesPopup";
import UserColumn from "../../../common/UserColumn";
import PopupComponent from "../../../common/PopupComponent";
import { handleTableScroll } from "../../../../utils/commonFunction";

const OnlineClassesTable = (props) => {
  const { id, type } = useParams();
  const history = useHistory();
  const [portalId, setPortalId] = useState("");
  const [search, setSearch] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState("");
  const [launchDetail, setLaunchDetail] = useState({});
  const [status, setStatus] = useState({ arr: [], checkObj: {} });
  const [classId, setClassId] = useState(null);
  const [editorState, setEditorState] = useState();
  const [loader, setLoader] = useState([]);
  const [selectedFile, setSelectedFile] = useState();
  const [onlineClass, setOnlineClass] = useState({
    course_id: "",
    class_id: "",
  });
  const [searchShowing, setSearchShowing] = useState({ action41: true });
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [sortkey, setSortKey] = useState("date");
  const [sortOrder, setSortOrder] = useState("ASC");
  const [courseIds, setCourseIds] = useState(id);
  const [dashboardList, setDashBoardList] = useState([]);
  const [breakoutRoomList, setBreakoutRoomList] = useState([]);
  const [showing, setShowing] = useState([10]);
  const [recurring, setRecurring] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [showCkEditor, setShowCkEditor] = useState(false);
  const [analysisBtn, setAnalysisBtn] = useState({ disable: true, link: "" });
  const [learningMethod, setLearningMethod] = useState({ arr: [], checkObj: {} });
  const [virtualDropdown, setVirtualDropdown] = useState({ course: [], intake: [], lecturer: [], status: [], learning: [] });
  const [popUpState, setPopUpState] = useState({ cid: 0, intake: 0, act: "" })
  const [isShowingAllUser, setIsShowingAllUser] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [launchLoading, setLaunchLoading] = useState(false);
  const [refreshList, setRefreshList] = useState();
  const [updateRecordingList, setUpdateRecordingList] = useState(true);
  const [isRecExpanded,setIsRecExpanded] = useState(false)
  
  // Notification related states
  const notificationInitialState = {
    class_id: "",
    subject: "",
    message: "",
    attachment: {},
    class_name: "",
  };
  const [notificationFields, setNotificationFields] = useState(
    notificationInitialState
  );

  useEffect(() => {
    let data = {
      course_id: id
    }
    GetVritualClassDropdown(data).then((data) => {
      setVirtualDropdown({ ...data.data, course: data.data.course, intake: data.data.intakes, lecturer: data.data.lectures, status: data.data.statusType, learning: data.data.studyType })
    }).catch(err => console.log(err))
  }, [])



  /**
   * subject
      message
      attachment
      class_id
      isEmail
      isPushnotificationActive
   */
  // End of Notification related states

  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };
  const handleShowingFilter = (e) => {
    const value = e.target.value;
    if (showing.includes(value)) {
      setShowing([]);
    } else {
      setShowing([value]);
    }

    let id = e.target.id;
    if (id == "action41") {
      setSearchShowing({ action41: !searchShowing.action41 });
    } else {
      setSearchShowing({
        [id]: !searchShowing[id],
        action41: false,
      });
    }
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };

  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };

  const handleSearchFilter = (e) => {
    const value = e.target.value;
    setDebouncedTerm(value);
  };

  useEffect(() => {
    let timeOut = setTimeout(() => {
      setSearch(debouncedTerm)
    }, 1000);

    return () => {
      clearTimeout(timeOut)
    }
  }, [debouncedTerm])

  const resetFilter = () => {
    setStatus({ arr: [], checkObj: {} });
    setSearch("");
    setDebouncedTerm("");
    setShowing([10]);
    setSearchShowing({});
    setLearningMethod({ arr: [], checkObj: {} })
  };

  const getOnlineClassesList = () => {
    setLoading(true);
    const data = {
      pageNo: page,
      limit: perPage,
      search: search,
      viaStatus: status.arr.length > 0 ? JSON.stringify(status.arr) : [],
      viaStudyType: learningMethod.arr.length ? JSON.stringify(learningMethod.arr) : [],
      key: sortkey,
      sort: sortOrder,
      showing: showing,
      course_id: Number(courseIds),
    };

    GetListOfDashboard(data)
      .then((res) => {
        setDashBoardList(res.data?.result1);
        setTotalRows(res.data?.Pagination?.total);        
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };
  useEffect(() => {
    getAve()
      .then((res) => {
      })
      .catch((err) => {
        console.log(err);
      });
    $(".drag-modal").draggable({
      handle: ".drag-handler"
    });
  }, []);

  useEffect(() => {
    getOnlineClassesList();
  }, [
    search,
    page,
    perPage,
    status,
    sortkey,
    sortOrder,
    courseIds,
    showing,
    learningMethod,
    refreshList
  ]);

  const onChangeStatus = (id, recordingChecked) => {
    UpdateClassRecordStatus(id, recordingChecked == 1 ? false : true)
      .then((response) => {
        // getOnlineClassesList();
        if(response.status==200){
          setRefreshList(recordingChecked == 1);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  function launchButton(row) {
    setLaunchLoading(true)
    setPortalId(row?.id);
    setLaunchDetail({});
    let formData = new FormData();
    formData.append("vclassID", row?.id);
    formData.append("class_type", row?.class_type);
    GetLuanchButtonData(formData)
      .then((data) => {
        const launchBtnData = data && data.data ? data.data : {};
        setLaunchDetail({ ...row, ...launchBtnData });
        if(launchBtnData.breakoutRooms.length>0){setBreakoutRoomList(launchBtnData.breakoutRooms)}
        const sendData = {
          server_id: row.server_id,
          bbb_class_id: row.big_blue_button_id
        }
        GetLearningDashboardURL(sendData).then((res) => {
          if (res.status === 200) {
            // console.log(res.data.URL);
            if (res.data.URL) {
              setAnalysisBtn({ disable: false, link: res.data.URL })
            }
          }
        }).catch((err) => { console.log(err) })
        setLaunchLoading(false)
      })
      .catch((error) => {
        console.log("error >>>>>>> ", error);
        setLaunchLoading(false)
      });
  }
  function startShow(rating) {
    var arrCount = [];
    for (var i = 0; i < rating; i++) {
      arrCount.push(i);
    }
    let tempValues = arrCount.map((val) => {
      return (
        <span className="icon-w" key={val}>
          <i
            className="fa fa-star"
            style={{ color: "#FFD700" }}
            title="Rating"
          ></i>
        </span>
      );
    });
    return tempValues;
  }

  const handleShowingUser = (isShowing, id) => {
    let tempArr = isShowingAllUser;
    if (isShowing) {
      tempArr.splice(tempArr.indexOf(id), 1)
    } else {
      tempArr.push(id)
    }
    setIsShowingAllUser([...tempArr])
  }

  const columns = useMemo(() => [
    {
      name: "Title",
      selector: "class_title",
      sortField: "title",
      sortable: true,
      cell: (row) => (
        <>
          <span
            className="overflow-ellipsis2 fst_line2 feature-name fist-name-box"
            title={row.class_title}
          >
            <span
              data-toggle="modal"
              data-target="#launch"
              className="as-text-blue curser">
              <span onClick={() => {
                launchButton(row);
                setOnlineClass({
                  class_id: row.class_id,
                  course_id: row.courseID,
                });
              }} className="textLimit100">{row.class_title ? row.class_title : "-"} </span>
            </span>
            {row.isRecurring == 1 && row.isRecurringDifferent == 1 ? (
              <div className="icon-w-text">
                <span className="icon-w">
                  <i className="fal fa-refresh not-refresh"></i>
                </span>
              </div>
            ) : row.isRecurring == 1 && (
              <div className="icon-w-text">
                {" "}
                <span className="icon-w">
                  <i className="fal fa-refresh"></i>
                </span>
              </div>
            )}
          </span>

        </>
      ),
    },
    {
      name: "Date",
      selector: "date",
      sortField: "date",
      sortable: true,
      cell: (row) => {
        return (
          <div className="">
            <p className="right-space">
              {moment(row.class_date, "YYYY-MM-DD").format(TABLE_DATE_FORMAT)}
            </p>
            <p>
              {moment(row.class_from, "h:mm A").format(TABLE_TIME_FORMAT)} -{" "}
              {moment(row.class_to, "h:mm A").format(TABLE_TIME_FORMAT)}
            </p>
          </div>
        );
      },
    },
    {
      name: "Course",
      selector: "courseName",
      sortField: "coures",
      sortable: true,
      cell: (row) => (
        <>
          <span className="overflow-ellipsis2 fst_line2" title={row.courseName}>
            <Link
              className="as-text-blue curser textLimit100"
              to={`/courseAdministration/coursesdetails/${row.intakeID}/dashboard/show`}
            >
              {row.courseName ? row.courseName : "-"}
            </Link>
          </span>
        </>
      ),
    },
    {
      name: "Intake",
      selector: "intakeYear",
      sortable: true,
      sortField: "Intake",
      cell: (row) => (
        <p>
          {row.intakeNumber || row.intakeYear ? (
            <>
              {" "}
              {row.intakeYear ? row.intakeYear : ""}-{row.intakeNumber ? row["intakeNumber"] : ""}
            </>
          ) : (
            "-"
          )}
        </p>
      ),
    },

    {
      name: "Delivery",
      selector: "class_type",
      sortField: "type",
      sortable: true,
      cell: (row) => <OnlineClassType row={row} />
    },

    {
      name: "Status",
      selector: "status",
      sortable: false,
      cell: (row) =>
        row?.status ? (
          <div>
            {row?.status && (RenderClassStatus(row?.status).html
            )}
            {row?.status === "Live" && row?.subStatus === "Launched" ? <>
              {row?.isVirtual ? <span className="p-1"><i className="fal fa-house-signal"></i></span> : null}
              {row?.isCampus ? <span className="p-1"><i className="fal fa-university"></i></span> : null}
            </> : null}
            {row?.subStatus && (
              RenderClassStatus(row?.subStatus).html
            )}
            {row?.status == "Live" && row?.subStatus == "Launched" ? (
              <>
                <p className="textLimit100">{row?.nested_started_at ? " at " + moment.unix(row?.nested_started_at).format(TABLE_TIME_FORMAT) : ""}
                  <br />
                  {row?.nestedStarted_by ? " by " + row?.nestedStarted_by : ""}
                </p>
              </>
            ) : (
              ""
            )}

            {(row?.status == "Past" || row?.status == "Live") &&
              row?.subStatus == "Ended" &&
              (row?.ended_by == row?.lect_id || row?.ended_by >= 0 ? (
                <p className="textLimit100">
                  {row?.nested_ended_at ? " at " : ""}
                  {moment.unix(row?.nested_ended_at).format(TABLE_TIME_FORMAT)}
                  <br />
                  {/* {row?.nestedEnded_by ? " by " : ""} */}
                  <span className="one-line-truncate"
                    title={row?.nestedEnded_by ? `by ${row?.nestedEnded_by}` : ` by ${row?.lecture_name}`}
                  >
                    {row?.nestedEnded_by ? `by ${row?.nestedEnded_by}` : ` by ${row?.lecture_name}`}
                  </span>
                </p>
              ) : (
                ""
              ))}
          </div>
        ) : (
          "NA"
        ),
    },

    {
      name: "Lecturer",
      selector: "lecture_name",
      sortable: true,
      sortField: "lecture",
      cell: (row) => row?.lecture_name ? <UserColumn
        firstName={row?.lecture_name.split(" ")[0] ?? ""}
        lastName={row?.lecture_name.replace(/ +/g, " ").split(" ")[1] ?? ""}
        imgUrl={row?.lecturer.picture_me}
        email={row?.lecturer?.Email}
        mobile={row?.lecturer?.Mobile}
        userId={row?.lect_id}
        userActiveStatus={row?.lecturer?.Status}
        role="lecturer"
        isPopUpRight
      /> : ""
    },
    {
      name: "Guest Lecturer",
      cell: (row) => {
        return isShowingAllUser.includes(row.id) ? <div>
          <div className="assigned-profile-tit">
            {row.multiple_lecture.length ? row.multiple_lecture.map((data, index) => <UserColumn
              firstName={data?.get_lecture_details?.Firstname}
              lastName={data?.get_lecture_details?.Lastname}
              imgUrl={data?.get_lecture_details?.picture_me}
              email={data?.get_lecture_details?.Email}
              mobile={data?.get_lecture_details?.Mobile}
              userId={data?.lecturer_id}
              userActiveStatus={data?.get_lecture_details?.Status}
              role="lecturer"
              key={index}
              isUserNameHidden={!(isShowingAllUser.includes(data?.class_id) || row.multiple_lecture.length == 1)}
              isPopUpRight={true}
            />) : "-"}</div>
          {row.multiple_lecture.length && row.multiple_lecture.length > 1 ? <div
            className="table-expand-btn"
            onClick={() => handleShowingUser(true, row.id)}>
            See Less
          </div> : ""}
        </div> : <div className="assigned-profile-overlay-out">
          <div className="assigned-profile-overlay">
            {row.multiple_lecture.length ? row.multiple_lecture.map((data, index) => {
              return index < 3 ? <UserColumn
                firstName={data?.get_lecture_details?.Firstname}
                lastName={data?.get_lecture_details?.Lastname}
                imgUrl={data?.get_lecture_details?.picture_me}
                email={data?.get_lecture_details?.Email}
                mobile={data?.get_lecture_details?.Mobile}
                userId={data?.lecturer_id}
                userActiveStatus={data?.get_lecture_details?.Status}
                role="lecturer"
                key={index}
                isUserNameHidden={!(isShowingAllUser.includes(data?.class_id) || row.multiple_lecture.length == 1)}
                isPopUpRight={true}
              /> : ""
            }) : "-"}</div>
          {row.multiple_lecture.length && row.multiple_lecture.length > 1 ? <div
            className="table-expand-btn"
            onClick={() => handleShowingUser(false, row.id)}>
            See More
          </div> : ""}
        </div>
      },
    },
    {
      name: "Recording",
      selector: "",
      sortable: false,
      cell: (row) => {
        var totalDownloadCount = row.breakoutRooms.length + 1
        var totalViewCount = row.breakoutRooms.length + 1
        var availableViewCount =0
        var availableDownloadCount= 0

        if(row.isRecorded === 1 && row.viewRecording.link){availableViewCount++}
        if(row.isRecorded === 1 && row.recording.link){availableDownloadCount++}

        row.breakoutRooms.forEach((room)=>{
          if(room.ViewAvailable!=null){
            availableViewCount++
          }
          if(room.DownloadAvailable!=null){
            availableDownloadCount++
          }
        })


        return row.class_type == "on_campus" ? (
          ""
        ) : (
          <div className="assessment-08">
            {/* <div className="mb-2 text-centre">
              <PermissionsGate
                scopes={["cedit"]}
                errorProps={{ disabled: true }}
              >
                {row.class_type == "on_campus" ? <></> : row.subStatus ==
                  "Ended" ? (
                  <> {"Class Recorded"}
                    <button
                      disabled
                      title={
                        row.isRecorded && row.isRecordingAvailable ? "Yes" : "No"
                      }
                      className={"btn rounded-circle btn-status-1 ml-1 " +
                        (row.isRecorded == 1
                          ? "btn-success btn-yes"
                          : "btn-danger online-recording btn-no")
                      }
                    >
                      {row.isRecorded == 1 ? (
                        <>
                          <i className="fal fa-check"></i> Yes
                        </>
                      ) : (
                        <>
                          <i className="fal fa-times"></i> No
                        </>
                      )}
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </PermissionsGate>
            </div> */ }

{row.status === "Live" && row.subStatus === "Launched" ? (
  <>
    Recording Started
    <button
      type="button"
      disabled={!(row.status === "Live" && row.subStatus === "Launched")}
      title={row.isRecordingChecked ? "Yes" : "No"}
      className={
        "btn rounded-circle btn-status-1 ml-1 " +
        (row.isRecordingChecked === 1
          ? "btn-success btn-yes "
          : "btn-danger btn-no ")
      }
      onClick={() => {
        onChangeStatus(row.big_blue_button_id, row.isRecordingChecked);
      }}
    >
      {row.isRecordingChecked === 1 ? (
        <>
          <i className="fal fa-check"></i> Yes
        </>
      ) : (
        <>
          <i className="fal fa-times"></i> No
        </>
      )}
    </button>
  </>
) : row.isRecorded === 1 || row.breakoutRooms.length > 0 ? (
  row.breakoutRooms.length === 0 ? (
    <>
    
      {row.isRecorded === 1 && row.viewRecording.link ? (
        <>
          <a
            href={row.viewRecording.link}
            target="_blank"
            className="dashboard-view-button"
            title="View Recording"
          >
            <i className="fal fa-eye"></i> <span className="as-text-blue cursor">View</span>
          </a>
          &nbsp;
          <CopyToClipboard
            data={`
              <strong>Class name: </strong> ${row.class_title}
              <br />
              <strong>Class date and time: </strong> ${moment(row.class_date, "YYYY-MM-DD").format(TABLE_DATE_FORMAT)} ${moment(row.class_from, "h:mm A").format(TABLE_TIME_FORMAT)} - ${moment(row.class_to, "h:mm A").format(TABLE_TIME_FORMAT)}
              <br />
              <strong>Lecturer: </strong> ${row.lecture_name}
              <br />
              <a href="${row.viewRecording.link}" target="_blank" className="dashboard-view-button" title="View Recording">View</a>
              <br />
              <a href="${row.recording.link}" target="_blank" className="dashboard-view-button" title="Download Recording">Download</a>
            `}
          />
        </>
      ) : (
        <>
          {row.recording.status === "Processing" ? (
            <>
              <i className="fal fa-eye"></i>
              <span className="pl-1">Processing</span>
            </>
          ) : (
            <>
              <i className="fal fa-times"></i> &nbsp;Not Recorded
            </>
          )}
        </>
      )}
      <br />
      {row.isRecorded === 1 && row.recording.link ? (
        <>
          <a
            href={row.recording.link}
            target="_blank"
            className="dashboard-view-button"
            title="Download Recording"
          >
            <i className="fal fa-download"></i> <span className="as-text-blue cursor">Download</span>
          </a>
          &nbsp;
        </>
      ) : (
        <>
          {row.recording.status === "Processing" ? (
            <>
              <i className="fal fa-download"></i>
              <span className="pl-1">Processing</span>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  ) : isRecExpanded ? (
    
    <>
      <b>Class Recording</b>
      <div>
      {row.isRecorded === 1 && row.viewRecording.link ? (
        <>
          <a
            href={row.viewRecording.link}
            target="_blank"
            className="dashboard-view-button"
            title="View Recording"
          >
            <i className="fal fa-eye"></i> <span className="as-text-blue cursor">View</span>
          </a>
          &nbsp;
          <CopyToClipboard
            data={`
              <strong>Class name: </strong> ${row.class_title}
              <br />
              <strong>Class date and time: </strong> ${moment(row.class_date, "YYYY-MM-DD").format(TABLE_DATE_FORMAT)} ${moment(row.class_from, "h:mm A").format(TABLE_TIME_FORMAT)} - ${moment(row.class_to, "h:mm A").format(TABLE_TIME_FORMAT)}
              <br />
              <strong>Lecturer: </strong> ${row.lecture_name}
              <br />
              <a href="${row.viewRecording.link}" target="_blank" className="dashboard-view-button" title="View Recording">View</a>
              <br />
              <a href="${row.recording.link}" target="_blank" className="dashboard-view-button" title="Download Recording">Download</a>
            `}
          />
        </>
      ) : (
        <>
          {row.recording.status === "Processing" ? (
            <>
              <i className="fal fa-eye"></i>
              <span className="pl-1">Processing</span>
            </>
          ) : (
            <>
              <i className="fal fa-times"></i> &nbsp;Not Recorded
            </>
          )}
        </>
      )}
      <br />
      {row.isRecorded === 1 && row.recording.link ? (
        <>
          <a
            href={row.recording.link}
            target="_blank"
            className="dashboard-view-button"
            title="Download Recording"
          >
            <i className="fal fa-download"></i> <span className="as-text-blue cursor">Download</span>
          </a>
          &nbsp;
        </>
      ) : (
        <>
          {row.recording.status === "Processing" ? (
            <>
              <i className="fal fa-download"></i>
              <span className="pl-1">Processing</span>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      </div>
      <div>
      {row?.breakoutRooms.map((room) => {
      return (
    <div key={room.class_title}>
      <b>{room.class_title}</b>
      <div>
        {room.IsRecorded === 1 && room.viewURL ? (
          <>
            <a
              href={room.viewURL}
              target="_blank"
              className="dashboard-view-button"
              title="View Recording"
            >
              <i className="fal fa-eye"></i> <span className="as-text-blue cursor">View</span>
            </a>
            &nbsp;
            {/* You can uncomment this block when needed */}
            {/* <CopyToClipboard
              data={`
                <strong>Class name: </strong> ${row.class_title}
                <br />
                <strong>Class date and time: </strong> ${moment(row.class_date, "YYYY-MM-DD").format(TABLE_DATE_FORMAT)} ${moment(row.class_from, "h:mm A").format(TABLE_TIME_FORMAT)} - ${moment(row.class_to, "h:mm A").format(TABLE_TIME_FORMAT)}
                <br />
                <strong>Lecturer: </strong> ${row.lecture_name}
                <br />
                <a href="${row.viewRecording.link}" target="_blank" className="dashboard-view-button" title="View Recording">View</a>
                <br />
                <a href="${row.recording.link}" target="_blank" className="dashboard-view-button" title="Download Recording">Download</a>
              `}
            /> */}
          </>
        ) : (
          <>
            {room.IsRecorded === 1 && room.ViewAvailable == null ? (
              <>
                <i className="fal fa-eye"></i>
                <span className="pl-1">Processing</span>
              </>
            ) : (
              <>
                <i className="fal fa-times"></i> &nbsp;Not Recorded
              </>
            )}
          </>
        )}
        <br />
        {room.IsRecorded === 1 && room.downloadURL ? (
          <>
            <a
              href={room.downloadURL}
              target="_blank"
              className="dashboard-view-button"
              title="Download Recording"
            >
              <i className="fal fa-download"></i> <span className="as-text-blue cursor">Download</span>
            </a>
            &nbsp;
          </>
        ) : (
          <>
            {room.IsRecorded === 1 && room.DownloadAvailable == null ? (
              <>
                <i className="fal fa-download"></i>
                <span className="pl-1">Processing</span>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </div>
      );
      })}

      </div>
      <div
        className="table-expand-btn"
        onClick={() => {
          setIsRecExpanded(false);
        }}
      >
        Show Less
      </div>
    </>
  ) : (
    <>
      <i className="fal fa-eye"></i> <span className="cursor">View</span>&nbsp;<b>{`${availableViewCount}/${totalViewCount}`}</b>
      <br />
      <i className="fal fa-download"></i> <span className="cursor">Download</span>&nbsp;<b>{`${availableDownloadCount}/${totalDownloadCount}`}</b>
      <br />
      
      <div 
        className="table-expand-btn"
        onClick={() => {
          setIsRecExpanded(true);
        }}
      >
        Show More
      </div>
    </>
  )
) : (
  <><i className="fal fa-times"></i> &nbsp;Not Recorded</>
)}

          </div>
        );
      },
    },

    {
      name: "Insights",
      selector: "",
      minWidth: "350px",
      sortable: false,
      cell: (row) => {
        let downloadrate =
          (row?.arrAttendance?.getattend / row?.arrAttendance?.total) * 100;

        return (
          <div className="popup-item poup-item-box">
            <div className="icon-area-main">
              <div className="icon-area">
                <div
                // className="icon-area-row "
                >
                  <div className="icon-w-text">
                    <span className="icon-w">
                      <i
                        className="fal fa-users"
                        title="Student attendance"
                      ></i>
                    </span>
                    {row?.arrAttendance ? (
                      <>
                        {" "}
                        {row?.arrAttendance?.getattend} /{" "}
                        {row?.arrAttendance?.total} (
                        {downloadrate ? downloadrate.toFixed(2) : 0}%)
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="icon-w-text">
                    <span className="icon-w">
                      <i
                        className="fal fa-university"
                        title="Campus attendance"
                      ></i>
                    </span>
                    {row?.arrAttendanceCampus ? (
                      <>
                        {" "}
                        {row?.arrAttendanceCampus?.getattend} /{" "}
                        {row?.arrAttendanceCampus?.total} (
                        {row?.arrAttendanceCampus &&
                          row?.arrAttendanceCampus?.attendpercentage > 0
                          ? row?.arrAttendanceCampus?.attendpercentage &&
                          row?.arrAttendanceCampus?.attendpercentage.toFixed(2)
                          : 0}
                        %)
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="icon-w-text">
                    <span className="icon-w">
                      <i
                        className="fal fa-house-signal"
                        title="Remote attendance"
                      ></i>
                    </span>
                    {row?.arrAttendanceOnline ? (
                      <>
                        {" "}
                        {row?.arrAttendanceOnline?.getattend} /{" "}
                        {row?.arrAttendanceOnline?.total} (
                        {row?.arrAttendanceOnline &&
                          row?.arrAttendanceOnline?.attendpercentage > 0
                          ? row?.arrAttendanceOnline?.attendpercentage &&
                          row?.arrAttendanceOnline?.attendpercentage.toFixed(2)
                          : 0}
                        %)
                      </>
                    ) : (
                      "-"
                    )}
                  </div>
                  <div className="icon-w-text">
                    {row?.ratingArr.avg_rating > 0
                      ? startShow(row?.ratingArr.avg_rating)
                      : ""}
                    {row?.ratingArr.avg_rating && row?.ratingArr.avg_rating > 0
                      ? `${row?.ratingArr.total_reviewed_user}/${row?.ratingArr.total_user}`
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: "Actions",
      selector: "",
      cell: (row) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons">
            <PermissionsGate
              scopes={["cocview"]}
              errorProps={{ disabled: true }}
            >
              <button
                className="btn btn-primary rounded-circle"
                title="Launch"
                data-toggle="modal"
                data-target="#launch"
                onClick={() => {
                  launchButton(row);
                  setOnlineClass({
                    class_id: row.class_id,
                    course_id: row.courseID,
                  });
                }}
              >
                <i className="fal fa-rocket"></i>
              </button>
            </PermissionsGate>


            <div className="dropdown btn-dropdown-item">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                {/* DO NOT DELETE BELOW COMMENTED BLOCK */}
                {/* <PermissionsGate
                  scopes={["cocadd"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Quick Notification"
                    data-toggle="modal"
                    onClick={() => {
                      setShowCkEditor(true);
                      setSelectedFile("");
                      setEditorState("")

                      $("#notifications").modal("show");
                      // setNotificationFields([...notificationFields, { class_id: row.class_id, class_name: row.class_title }])
                      setNotificationFields((prevState) => {
                        return {
                          ...prevState,
                          class_id: row.id,
                          class_name: row.class_title,
                        };
                      });
                    }}
                  >
                    <i className="fal fa-bell"></i>
                  </button>
                </PermissionsGate> */}

                {row.status == "Past" && <PermissionsGate
                  scopes={["cocview"]}
                  RenderError={() => (
                    <button
                      className="btn btn-primary rounded-circle"
                      title="Class Report"
                    >
                      <i className="fal fa-users"></i>
                    </button>
                  )}
                >
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Class Report"
                    onClick={() => history.push(`/classReport/summary/${id}/${row.class_id}`)}
                  >
                    <i className="fal fa-users"></i>
                  </button>
                </PermissionsGate>}
                {row.status !== "Live" && ((row.launch_disable != 1 && row.status !== "Past") && (
                  (row.isRecurring == "0" || (row.isRecurring == 1 && row.isRecurringDifferent == 1)) ?
                    <PermissionsGate
                      scopes={["cocedit"]}
                      RenderError={() =>
                        <button
                          disabled
                          className="btn btn-primary rounded-circle"
                          title="Open"
                        >
                          <i className="fal fa-folder-open"></i>
                        </button>}
                    >
                      <Link
                        to={{ pathname: "/courseAdministration/coursesdetails/" + id + "/onlineClasses/" + row.id + "/open/" + row.isRecurring }}
                        className="btn btn-primary rounded-circle"
                        title="Open"
                      >
                        <i className="fal fa-folder-open"></i>
                      </Link>
                    </PermissionsGate> :
                    <PermissionsGate
                      scopes={["cocedit"]}
                      errorProps={{ disabled: true }}
                    >
                      <button
                        className="btn btn-primary rounded-circle"
                        title="Open"
                        data-toggle="modal"
                        data-target="#editModal"
                        onClick={() => {
                          setRecurring(row.isRecurring);
                          setClassId(row.id)
                        }}
                      >
                        <i className="fal fa-folder-open"></i>
                      </button>
                    </PermissionsGate>
                ))}
                {row.status != "Past" &&
                  <PermissionsGate
                    scopes={["cocview"]}
                    errorProps={{ disabled: true }}
                  >
                    <Link
                      target="_blank"
                      to={`/classReport/summary/${id}/${row.class_id}`}
                    >
                      <button
                        className="btn btn-primary rounded-circle"
                        title="Class Report"
                      >
                        <i className="fal fa-users"></i>
                      </button>
                    </Link>
                  </PermissionsGate>}
                <PermissionsGate
                  scopes={["cocadd"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Class Recording"
                    data-toggle="modal"
                    data-target="#classrecording"
                    onClick={() => {
                      setClassId(row.id);
                    }}
                  >
                    <i className="fal fa-video"></i>
                  </button>
                </PermissionsGate>
                <PermissionsGate
                  scopes={["cocdelete"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Unlink class"
                    onClick={() => handleDelete(row.id, "unlink", row)}
                  >
                    <i className="fal fa-unlink"></i>
                  </button>
                </PermissionsGate>
                <PermissionsGate
                  scopes={["cocdelete"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Cancel Class"
                    onClick={() => handleDelete(row.id, "cancel", row)}
                  >
                    <i className="fal fa-times"></i>
                  </button>
                </PermissionsGate>
                <PermissionsGate
                  scopes={["cocdelete"]}
                  errorProps={{ disabled: true }}
                >
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    onClick={() => handleDelete(row.id, "remove", row)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ], [isShowingAllUser, isRecExpanded]);

  const breakoutColumns = useMemo(() => [
    {
      name: "Room Title",
      selector: "class_title",
      sortField: "title",
      sortable: true,
      cell: (row) => (
        <>
          <span
            className="overflow-ellipsis2 fst_line2 feature-name fist-name-box"
            title={row.class_title}
          >
            <span
              data-toggle="modal"
              data-target="#launch"
              className="as-text-blue curser">
              <span onClick={() => {
                launchButton(row);
                setOnlineClass({
                  class_id: row.class_id,
                  course_id: row.courseID,
                });
              }} className="textLimit100">{row.class_title ? row.class_title : "-"} </span>
            </span>
            {row.isRecurring == 1 && row.isRecurringDifferent == 1 ? (
              <div className="icon-w-text">
                <span className="icon-w">
                  <i className="fal fa-refresh not-refresh"></i>
                </span>
              </div>
            ) : row.isRecurring == 1 && (
              <div className="icon-w-text">
                {" "}
                <span className="icon-w">
                  <i className="fal fa-refresh"></i>
                </span>
              </div>
            )}
          </span>

        </>
      ),
    },
    {
      name: "Date",
      selector: "date",
      sortField: "date",
      sortable: true,
      cell: (row) => {
        return (
          <div className="">
            <p className="right-space">
              {moment(row.class_date, "YYYY-MM-DD").format(TABLE_DATE_FORMAT)}
            </p>
            
          </div>
        );
      },
    },
  
    {
      name: "Recordings",
      selector: "",
      sortable: false,
      cell: (row) => {
        

        return (<div>
        {row.IsRecorded === 1 && row.viewURL ? (
          <>
            <a
              href={row.viewURL}
              target="_blank"
              className="dashboard-view-button"
              title="View Recording"
            >
              <i className="fal fa-eye"></i> <span className="as-text-blue cursor">Available to View</span>
            </a>
            &nbsp;
            {/* You can uncomment this block when needed */}
            <CopyToClipboard
              data={`
                <strong>Class name: </strong> ${row.class_title}
                <br />
                <strong>Class date and time: </strong> ${moment(row.class_date, "YYYY-MM-DD").format(TABLE_DATE_FORMAT)} ${moment(row.class_from, "h:mm A").format(TABLE_TIME_FORMAT)} - ${moment(row.class_to, "h:mm A").format(TABLE_TIME_FORMAT)}
                <br />
                <strong>Lecturer: </strong> ${row.lecture_name}
                <br />
                <a href="${row.viewURL}" target="_blank" className="dashboard-view-button" title="View Recording">View</a>
                <br />
                <a href="${row.downloadURL}" target="_blank" className="dashboard-view-button" title="Download Recording">Download</a>
              `}
            />
          </>
        ) : (
          <>
            {row.IsRecorded === 1 && row.ViewAvailable == null ? (
              <>
                <i className="fal fa-eye"></i>
                <span className="pl-1">Processing</span>
              </>
            ) : (
              <>
                <i className="fal fa-times"></i> &nbsp;Not Recorded
              </>
            )}
          </>
        )}
        <br />
        {row.IsRecorded === 1 && row.downloadURL ? (
          <>
            <a
              href={row.downloadURL}
              target="_blank"
              className="dashboard-view-button"
              title="Download Recording"
            >
              <i className="fal fa-download"></i> <span className="as-text-blue cursor">Download Available</span>
            </a>
            &nbsp;
          </>
        ) : (
          <>
            {row.IsRecorded === 1 && row.DownloadAvailable == null ? (
              <>
                <i className="fal fa-download"></i>
                <span className="pl-1">Processing</span>
              </>
            ) : (
              <></>
            )}
          </>
        )}
      </div>)
      }      
        
        
        
        
        
   
    },

  ], [isShowingAllUser]);

  const handleDelete = (no, type = "", row) => {
    let msgType = "";
    let msg = "";
    let btnText = "";
    if (type == "remove") {
      btnText = "Yes, delete it!"
      msgType = "Deleted!";
      msg = "Class deleted successfully.";
    } else if (type == "cancel") {
      btnText = "Yes, cancel it!"
      msgType = "Canceled!";
      msg = "Class cancelled successfully.";
    } else if (type == "unlink") {
      btnText = "Yes, unlink it!"
      msgType = "Unlinked!";
      msg = "Class unlinked successfully.";
    }
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: btnText,
    }).then((result) => {
      if (result.isConfirmed) {
        // API Call
        // key : cid(class id)
        // act ="remove" (string)
        // intake:1514
        setPopUpState({ cid: no, intake: props.userId, act: type })
        if (type == "remove" && row.isRecurring == 1 && row.isRecurringDifferent != 1) {
          $("#deleteClass").modal("show");
          return
        } else if (type == "cancel" && row.isRecurring == 1 && row.isRecurringDifferent != 1) {
          $("#cancelClass").modal("show");
          return
        } else if (type == "unlink" && row.isRecurring == 1 && row.isRecurringDifferent != 1) {
          $("#unlinkClass").modal("show");
          return
        }
        let formData = new FormData();
        formData.append("cid", no);
        formData.append("intake", props.userId);
        formData.append("act", type);

        DeleteOnlineClass(formData)
          .then((data) => {
            getOnlineClassesList();

            Swal.fire(msgType, msg, "success");
          })
          .catch((error) => {
            console.log("error ===> ", error);
          });
      }
    });
  };

  const downloadRate = (count, total) => {
    let donwrate = `${count}/${total}`;
    return donwrate;
  };

  const exportData = (fileType, fileName) => {
    let data;
    let header = [
      "Title",
      "Date",
      "Course",
      "Intake",
      "Delivery",
      "Status",
      "Lecturer",
      "Recording",
      "Student attendance",
      "Campus Attendance",
      "Remote attendance",
      "View rate",
      "Download rate",
    ];
    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });

    GetListOfDashboard({
      pageNo: page,
      limit: perPage,
      search: search ? search : "",
      viaStatus: status.arr.length > 0 ? JSON.stringify(status.arr) : [],
      key: sortkey,
      sort: sortOrder,
      showing: showing,
      course_id: Number(courseIds),
      exportStaus: "true",
    }).then((res) => {
      data = res.data.result1;
      data = data.map((row) => ({
        Title: row.class_title ? row.class_title : "-",
        Date: `${moment(row.class_date).format(TABLE_DATE_FORMAT)} ${row.class_from
          } - ${row.class_to}`,
        Course: row.courseName ? row.courseName : "-",

        Intake: `${row.intakeYear ? row.intakeYear : ""}-${row.intakeNumber ? row["intakeNumber"] : "-"
          }`,
        Delivery:
          row.class_type === "bbb_class" ||
            row.class_type == "braincert_class" ||
            row.class_type == "joinme_class"
            ? "Virtual"
            : row.class_type === "on-campus"
              ? "On Campus"
              : "Hybrid",
        Status: row.status ? row.status : "-",
        Lecturer: row.lecture_name
          ? `${row.lecture_name}${row.multiple_lecture ? "," : ""
          }${row.multiple_lecture ? row.multiple_lecture.map(
            (data) =>
              data.get_lecture_details?.Firstname +
              " " +
              data.get_lecture_details?.Lastname +
              ""
          ) : ""}`
          : "-",
        Recording: row.downloadapilink == 1 ? "Yes" : "No",
        "Student attendance": `${row.arrAttendance
          ? `${row.arrAttendance?.getattend} / ${row.arrAttendance?.total
          } (${downloadRate(
            row.arrAttendance?.getattend,
            row.arrAttendance?.total
          )})`
          : "-"
          }`,
        "Campus Attendance": `${row.arrAttendanceCampus
          ? `${row.arrAttendanceCampus?.getattend} / ${row.arrAttendanceCampus?.total
          } (${row.arrAttendanceCampus?.getattend
            ? row.arrAttendanceCampus?.total
            : 0
          }%)`
          : "-"
          }`,
        "Remote attendance": `${row.arrAttendanceOnline
          ? `${row.arrAttendanceOnline?.getattend} / ${row.arrAttendanceOnline?.total
          } (${row.arrAttendanceOnline?.getattend
            ? row.arrAttendanceOnline?.total
            : 0
          }%)`
          : "-"
          }`,
        "View rate": `${row.arrViewRate?.count_view} / ${row.arrViewRate?.total}`,
        "Download rate": `${downloadRate(
          row.arrDownloadRate?.count_Download,
          row.arrDownloadRate?.total
        )}`,
      }));

      if (fileType === "csv") {
        const csvString = Papa.unparse({ fields: header, data });

        const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

        const blobURL = window.URL.createObjectURL(blob);

        const anchor = document.createElement("a");
        anchor.download = fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [
          "text/csv",
          anchor.download,
          anchor.href,
        ].join(":");
        anchor.click();

        setTimeout(() => {
          URL.revokeObjectURL(blobURL);
        }, 1000);
        Swal.close();
      } else if (fileType === "xlsx") {
        const compatibleData = data.map((row) => {
          const obj = {};
          header.forEach((col, index) => {
            obj[col] = row[col];
          });
          return obj;
        });

        let wb = XLSX.utils.book_new();
        let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
          header,
        });
        XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
        XLSX.writeFile(wb, `${fileName}.xlsx`);
        Swal.close();
        return false;
      }
      if (fileType === "pdf") {
        const compatibleData = data.map((row) => {
          return [
            row["Title"],
            row["Date"],
            row["Course"],
            row["Intake"],
            row["Delivery"],
            row["Status"],
            row["Lecturer"],
            row["Recording"],
            row["Student attendance"],
            row["Campus Attendance"],
            row["Remote attendance"],
            row["View rate"],
            row["Download rate"],
          ];
        });
        const doc = new JsPDF();
        doc.autoTable({
          head: [header],
          body: compatibleData,

          columnStyles: {
            0: {
              columnWidth: 10,
            },
            1: {
              columnWidth: 20,
            },
            2: {
              columnWidth: 15,
            },
            3: {
              columnWidth: 15,
            },
            4: {
              columnWidth: 13,
            },
            5: {
              columnWidth: 12,
            },
            6: {
              columnWidth: 20,
            },
            7: {
              columnWidth: 10,
            },
            8: {
              columnWidth: 10,
            },
            9: {
              columnWidth: 15,
            },
            10: {
              columnWidth: 15,
            },
            11: {
              columnWidth: 10,
            },
            12: {
              columnWidth: 10,
            },
          },
          styles: {
            minCellHeight: 10,
            minCellWidth: 5,
            halign: "left",
            fontSize: 8,
          },
        });
        doc.save(`${fileName}.pdf`);
        Swal.close();
        return false;
      }
    });
  };

  function cancelQuickNotification() {
    setNotificationFields({ ...notificationInitialState });
    setEditorState("");
    formik.resetForm();
    setShowCkEditor(false)
    setSelectedFile("");
  }

  const launchCampusClass = (action) => {
    Swal.fire({
      title: "Loading",
      onOpen: function () {
        Swal.showLoading()
      }
    })
    let launchData = new FormData();
    launchData.append("portal_id", portalId);
    launchData.append("action", action);
    classLaunch(launchData)
      .then((res) => {
        Swal.close()
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Updated successfully",
        });
        $("#launch").modal("hide");

        getOnlineClassesList();
      })
      .catch((err) => {
        console.log("launch error", err.response.data.message);
        Swal.close()
        Swal.fire({
          text: err.response.data.message,
          icon: "warning",
        });
      });
  };

  function launchVClass(id, loaderAt, isStart = true) {
    Swal.fire({
      title: "Loading",
      onOpen: function () {
        Swal.showLoading();
      },
    });
    if (launchDetail.class_type === "hybrid" && isStart === true) {
      setLoader([...loader, 1, 2]);
    } else if (launchDetail.class_type === "hybrid" && isStart === false) {
      setLoader([...loader, 3, 4]);
    } else {
      setLoader([loaderAt]);
    }
    let formData = new FormData();
    formData.append("portalID", id);
    // return;
    LaunchVirtualClass(formData, isStart)
      .then((data) => {
        setLoader([]);
        Swal.close()
        if (data.data?.message) {
          Swal.fire("Class Ended!", data.data?.message, "success");
          closeLaunchPopup();
          getOnlineClassesList();
        }
        if (data.data?.data?.url) {
          window.open(data.data?.data?.url.toString(), "_blank");
          //  Swal.fire("Lunched!", data.data.data.message, "success");
          closeLaunchPopup();
          getOnlineClassesList();
        } else {
          Swal.fire(isStart ? "Launched!" : "Ended!", data.data?.data?.message, "success").then(() => {
            closeLaunchPopup();
            getOnlineClassesList();
          });
        }
      })
      .catch((error) => {
        Swal.close()
        setLoader([]);
        console.log(">>>>> ", error);
      });
  }
  const copyGuestUrl = (url) => {
    navigator.clipboard.writeText(url);
  };

  function joinAsAdmin(joinData) {
    if (launchDetail.status !== "Live" && launchDetail.subStatus !== "Launched")
      return;
    const formData = new FormData();
    formData.append("class_id", launchDetail.id);
    formData.append(
      "learner_name",
      launchDetail.lerner ? launchDetail.lerner : ""
    );
    JoinClassAsAdmin(formData)
      .then((res) => {
        if (joinData == "copy") {
          Swal.fire({
            title: "Copied",
          }).then(() => {
            closeLaunchPopup();
            copyGuestUrl(res.data.joinMeetingURL);
          });
        } else {
          window.open(res.data.joinMeetingURL.toString(), "_blank");
          closeLaunchPopup();
        }
      })
      .catch((err) => {
        console.log("error while joining class as admin ---> ", err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err?.response?.data[0].message}`,
        });
      });
  }
  function closeLaunchPopup() {
    setAnalysisBtn({ disable: true, link: "" });
    $("#launch").modal("hide");
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      class_name: notificationFields.class_name,
      subject: "",
      attachment: "",
      content: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("Subject is required"),
      attachment: Yup.string().required("Attachment is required").trim(),

      content: Yup.string()
        .test("required", "Content is required", (value) => {
          if ((formatQuestion(editorState).replaceAll("&nbsp;", " ")).trim() == "" && !editorState.includes("<img")) {
            return false
          }
          if (!editorState) {
            return false
          }
          return true;
        })
        .trim()
    }),
    onSubmit: (values, { resetForm }) => {
      setDisabled(true);

      let formData = new FormData();
      formData.append("subject", values.subject);
      formData.append("message", editorState);
      formData.append("attachment", values.attachment);
      formData.append("class_id", notificationFields.class_id);
      formData.append("isEmail", 1);
      formData.append("isPushnotificationActive", 1);

      SendQuickNotification(formData)
        .then((data) => {
          if (data.status == 200) {
            setDisabled(false);
            setNotificationFields({
              class_id: "",
              subject: "",
              message: "",
              attachment: {},
              class_name: "",
            });
            $("#notifications").modal("hide");
            formik.resetForm();
            setEditorState("");
            setShowCkEditor(false);
            setSelectedFile("");
            Swal.fire("Notification!", "Successfully notified.", "success");
            getOnlineClassesList();
          }
        })
        .catch((error) => {
          console.log("error ---> ", error);
        });
    },
  });

  async function closeModal() {
    // setSelectedCourses([]);
    $("#classrecording").modal("hide");
  }

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  return (
    <>
      <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
        <div id="online_classes_list"
          className={`custom-table-div filter-search-icon card card-table-custom days-overdue-cell-width`}>
          <div className="search-filter-div">
            <div className="search-filter-div-left">
              <div className="system-administration-table table-responsive">
                <div className="table-responsive-div">
                  <div
                    id="assessment-table-main_wrapper"
                    className="dataTables_wrapper no-footer"
                  >
                    <div
                      id="assessment-table-main_filter"
                      className="dataTables_filter"
                    >
                      <label>
                        <input
                          type="search"
                          className=""
                          placeholder="Search"
                          aria-controls="assessment-table-main"
                          onChange={handleSearchFilter}
                          value={debouncedTerm}
                        />
                      </label>
                      <div className="filter-eff filter-data-btn">
                        <button className="filter-buttons">
                          <i className="fal fa-filter"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="filter-button-group">
                  <div className="assessment-table-filter">
                    <div className="btn-type-filter dropdown-comman">
                      <div className="dropdown multiselect">
                        <button
                          className={`btn btn-default dropdown-toggle ${type.length ? "btn-selected" : ""
                            }`}
                          type="button"
                          id="dropdownMenu5"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          title="Showing"
                        >
                          <span>
                            Showing <i className="fal fa-angle-down"></i>
                          </span>
                        </button>
                        <form className="dropdown-menu remove-list-style">
                          <ul aria-labelledby="dropdownMenu5">
                            <li className="">
                              <input
                                type="checkbox"
                                id="action41"
                                value="10"
                                onChange={handleShowingFilter}
                                checked={searchShowing["action41"]}
                              />
                              <label htmlFor="action41">
                                <span className="cat ">10 days</span>
                              </label>
                            </li>
                            <li className="">
                              <input
                                type="checkbox"
                                id="action42"
                                value="20"
                                onChange={handleShowingFilter}
                                checked={searchShowing["action42"]}
                              />
                              <label htmlFor="action42">
                                <span className="cat ">20 days</span>
                              </label>
                            </li>
                            <li className="">
                              <input
                                type="checkbox"
                                id="action43"
                                value="30"
                                onChange={handleShowingFilter}
                                checked={searchShowing["action43"]}
                              />
                              <label htmlFor="action43">
                                <span className="cat ">30 days</span>
                              </label>
                            </li>
                            <li className="">
                              <input
                                type="checkbox"
                                id="action44"
                                value="-1"
                                onChange={handleShowingFilter}
                                checked={searchShowing["action44"]}
                              />
                              <label htmlFor="action44">
                                <span className="cat ">All</span>
                              </label>
                            </li>
                          </ul>
                        </form>
                      </div>
                    </div>
                  </div>
                  <Tablefilter
                    filterName={"Status"}
                    optionArr={virtualDropdown.status}
                    state={status}
                    setState={setStatus}
                    renderLabelFunction={RenderClassStatus}
                  />
                  <Tablefilter
                    filterName="Learning Method"
                    optionArr={virtualDropdown.learning}
                    state={learningMethod}
                    setState={setLearningMethod}
                    renderLabelFunction={RenderLearningMethod}
                  />
                  <div className="button-reset dropdown-comman">
                    <button
                      className="btn btn-primary"
                      onClick={resetFilter}
                      title="Reset"
                      type="button"
                    >
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>
                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export spreadsheet"
                      onClick={() => {
                        exportData("xlsx", "Online Classes");
                      }}
                    >
                      <i className="fal fa-file-excel icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export CSV"
                      onClick={() => {
                        exportData("csv", "Online Classes");
                      }}
                    >
                      <i className="fal fa-file-csv icon"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      title="Export PDF"
                      onClick={() => {
                        exportData("pdf", "Online Classes");
                      }}
                    >
                      <i className="fal fa-file-pdf icon"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="search-filter-div-right">
              <div className=" filter-search-bar-blk">
                <div className="">

                  <PermissionsGate
                    scopes={["cocadd"]}
                    errorProps={{ disabled: true }}
                  >
                    <button
                      className="btn btn-primary mr-2"
                      onClick={() =>
                        history.push(
                          `/courseAdministration/coursesdetails/${id}/onlineClasses/select`
                        )
                      }
                      title="Link Class"
                    >
                      <i className="fal fa-link"></i>Link Class
                    </button>
                  </PermissionsGate>
                  <PermissionsGate
                    scopes={["cocadd"]}
                    errorProps={{ disabled: true }}
                  >
                    <button
                      className="btn btn-primary"
                      onClick={() =>
                        history.push(
                          `/courseAdministration/coursesdetails/${id}/onlineClasses/create`
                        )
                      }
                      title="New Class"
                    >
                      <i className="fal fa-plus"></i>New Class
                    </button>
                  </PermissionsGate>

                </div>
              </div>
            </div>
          </div>
          <DataTable
            // rowsPerPageOptions={[10, 15, 20, 25, 100]}
            paginationDefaultPage={page}
            progressPending={loading}
            progressComponent={<SkeletonTicketList />}
            data={dashboardList}
            columns={columns}
            defaultSortAsc={true}
            defaultSortField="date"
            onSort={handleSort}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            highlightOnHover={false}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
          />

        </div>
      </div>

      {showCkEditor && <div
        className="topic-add-modal modal fade zindexadd show"
        style={{ display: "block" }}
        id="notifications"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <form onSubmit={formik.handleSubmit}>
              <div className="modal-body p-0">

                <div className="modal-header modal-header-custom">
                  <h5 className="modal-title">
                    <i className="fal fa-bell"></i> Quick Notifications
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      formik.resetForm()
                      $("#notifications").modal("hide");
                      setEditorState("");
                      setShowCkEditor(false);
                      setSelectedFile("");
                      formik.setFieldValue("attachment", "")
                    }}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>

                </div>
                <div className="t p-30px">
                  <div className="topic-add-modal-content">
                    <div className="row center-label">
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Class Name</label>
                          <input
                            type="text"
                            className={"form-control form-control-aatch-file"}
                            disabled
                            title="Class Name"
                            value={formik.values.class_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Subject *</label>
                          <input
                            type="text"
                            title="Subject"
                            name="subject"
                            className={
                              "form-control form-control-aatch-file" +
                              (formik.errors.subject && formik.touched.subject
                                ? " is-invalid"
                                : "")
                            }
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}

                          />

                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Content *</label>
                          <HtmlInputEditor
                            editorState={editorState}
                            setEditorState={setEditorState}
                            hideSign={true}
                            isCKEditor={true}
                            isInvalid={
                              formik.touched.content &&
                              formik.errors.content
                            }
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group-blk mb-3">
                          <label>Attachment *</label>
                          <div className="form-group atttach-file m-0 d-flex align-items-center">
                            <label
                              className={
                                formik.errors.attachment &&
                                formik.touched.attachment &&
                                "file-req is-invalid"
                              }
                            >
                              <i className="fal fa-paperclip"></i>
                              <span>Add Attachment</span>
                              <input
                                type="file"
                                className="form-control  form-control-aatch-file"
                                onChange={(e) => {
                                  if (e.target.files.length) {
                                    formik.setFieldValue(
                                      "attachment",
                                      e.target.files[0]
                                    );
                                    setSelectedFile(URL.createObjectURL(e.target.files[0]));
                                  }
                                }}
                                placeholder="File"
                              />
                            </label>
                          </div>
                          {selectedFile ? (
                            <Link
                              className="mt-1 d-flex"
                              to={{ pathname: selectedFile }}
                              target="_blank"
                            >
                              {selectedFile}
                            </Link>
                          ) : null}
                        </div>

                      </div>

                    </div>
                  </div>
                  <div className="form-group form-group-save-cancel mt-3">
                    <PermissionsGate
                      scopes={["cedit"]}
                      errorProps={{ disabled: true }}
                    >
                      <button
                        type="submit"
                        className="btn btn-primary"
                        disabled={disabled}
                      // onClick={() => {
                      //   sendNotification();
                      // }}
                      >
                        {disabled ? (
                          <i className="fas fa-cog fa-spin"></i>
                        ) : (
                          <i className="fal fa-check"></i>
                        )}
                        Send
                      </button>
                    </PermissionsGate>
                    <button
                      type="button"
                      // type="submit"
                      className="btn btn-danger"
                      data-dismiss="modal"
                      onClick={() => {
                        cancelQuickNotification();
                      }}
                    >
                      <i className="fal fa-times"></i>
                      Close
                    </button>
                    {Object.keys(formik.values).map(key => {
                      if (formik.touched[key] && formik.errors[key]) {
                        return (
                          <div className="invalid-feedback d-block" key={key}>
                            {formik.errors[key]}
                          </div>
                        )
                      }
                    })}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>}

      <div
        className="topic-add-modal modal fade"
        id="classrecording"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-xl"
          role="document"
        >
          <div className="modal-content modal-border-update">
            <div className="modal-header modal-header-custom">
              <h5 className="modal-title">
                <i className="fal fa-video"></i> Class Recording
              </h5>
              <button
                type="button"
                className="close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  closeModal();
                }}
              >
                <i className="fal fa-times"></i>
              </button>
            </div>
            <div className="modal-body">
              <ClassRecordingModal classId={classId} updateRecordingList={updateRecordingList} setUpdateRecordingList={setUpdateRecordingList} />
              <hr />
              <div className="">
                <ClassRecordingForm classId={classId} updateRecordingList={updateRecordingList} setUpdateRecordingList={setUpdateRecordingList} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PopupComponent
        type="customModalBody"
        id="launch"
        header={{ iconName: "fa-tachometer-alt-average ", heading: "Class Launcher" }}
        modalBody={!launchLoading ? <div className="modal-body class-launch-modal">
          <div className="d-flex">
            <h4 className="landing-heading">{launchDetail.class_title}</h4>
            <div className="as-widget no-text-transformation ml-auto" title="Hybrid">
              {launchDetail.class_type ? RenderClassType(launchDetail.class_type).html : ""}
            </div>
          </div>
          <div className="time-date-bar d-flex align-items-center mt-2">
            <div className="time-date-bar-in">
              {launchDetail.class_date ? <><i className="fal fa-calendar-alt" title="Date and Time"></i>
                {` ${moment(launchDetail.class_date).format(
                  `dddd, ${TABLE_DATE_FORMAT}`
                )} ${launchDetail.class_from} - ${launchDetail.class_to
                  }`}</>
                : ""}
            </div>
            <div className="ml-3">
              {launchDetail.status ? RenderClassStatus(launchDetail.status).html : ""}
              {launchDetail.subStatus ? RenderClassStatus(launchDetail.subStatus).html : ""}
            </div>
          </div>
          <div className="room-name my-2">
            {launchDetail.multiple_room?.length ? launchDetail.multiple_room.map((item, index) => {
              return <span className="mr-2" key={index}><i className="fal fa-university" title="Campus"></i> {item.campus_room?.room_no}, {item.campus_room?.room_description}</span>
            }) : ""}
          </div>
          <div className="room-name my-2 d-flex">
            {launchDetail.arrAttendance ?
              <span className="mr-2">
                <i className="fal fa-users" title="Student Attendance"></i>{" "}
                {launchDetail.arrAttendance?.getattend} /{" "}{launchDetail.arrAttendance?.total} ({((launchDetail.arrAttendance?.getattend / launchDetail.arrAttendance?.total) * 100) ? ((launchDetail.arrAttendance?.getattend / launchDetail.arrAttendance?.total) * 100).toFixed(2) : 0}%)
              </span>
              : ""
            }
            {launchDetail.arrAttendanceCampus ? <span className="mr-2">
              <i className="fal fa-university" title="Campus Attendance"></i>{" "}
              {launchDetail.arrAttendanceCampus?.getattend} /{" "} {launchDetail.arrAttendanceCampus?.total} (
              {launchDetail.arrAttendanceCampus?.attendpercentage > 0
                ? launchDetail.arrAttendanceCampus?.attendpercentage.toFixed(2)
                : 0}
              %)
            </span> : ""}
            {launchDetail.arrAttendanceOnline ? <span className="mr-2">
              <i className="fal fa-house-signal" title="Remote Attendance"></i>{" "}
              {launchDetail.arrAttendanceOnline?.getattend} /{" "} {launchDetail.arrAttendanceOnline?.total} (
              {launchDetail.arrAttendanceOnline?.attendpercentage > 0
                ? launchDetail.arrAttendanceOnline?.attendpercentage.toFixed(2)
                : 0}
              %)
            </span> : ""}
            {launchDetail.subStatus === "Ended" ? <div className="icon-w-text mr-2">
              <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
              <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
              <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
              <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
              <span className="icon-w"><i className="fa fa-star" title="Rating"></i></span>
            </div> : ""}
          </div>
          <div className="mt-3 d-flex align-items-center flex-wrap">
            {(launchDetail.launch_show == 1 || launchDetail.on_campus_show == 1) && !((launchDetail.launch_disable == 1 || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")) && ((launchDetail.on_campus_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled"))) ? <div
              className={
                "box-style-new2 " +
                ((launchDetail.launch_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")
                  ? ""
                  : " bg-sidebar")
              }
            >
              {!loader.includes(1) && (
                <button
                  className="btn btn-success mr-2 mt-2"
                  disabled={(launchDetail.launch_disable == 1 || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")) && ((launchDetail.on_campus_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled"))}
                  onClick={() => {
                    if (launchDetail && (launchDetail.launch_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled"))) {
                      // launchVClass(launchDetail.id, 1)
                      (launchDetail.class_type == "hybrid" ||  launchDetail.class_type == "bbb_class")
                      ? launchVClass(launchDetail.id, 1)
                      : launchCampusClass("start")
                      // return
                    }
                    // if (launchDetail && (launchDetail.on_campus_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled"))) {
                    //   launchDetail.class_type == "hybrid"
                    //     ? launchVClass(launchDetail.id, 1)
                    //     : launchCampusClass("start")
                    // }
                  }}
                >
                  <i className="fal fa-rocket"></i> Launch Class
                </button>
              )}
            </div> : ""}
            {(launchDetail.end_meeting_show == 1 || launchDetail.end_campus_show == 1) && !(launchDetail.end_meeting_disable == 1 && launchDetail.end_campus_disable == 1) ? (
              <div
                className={
                  "box-style-new2 " +
                  (launchDetail.end_meeting_disable == 1
                    ? ""
                    : " bg-sidebar")
                }
              >
                {!loader.includes(3) && (
                  <button
                    // className={
                    //   launchDetail.end_meeting_disable == 0 &&
                    //   "dashboard_hover"
                    // }
                    className="btn btn-danger mr-2 mt-2"
                    disabled={launchDetail.end_meeting_disable == 1 && launchDetail.end_campus_disable == 1}
                    onClick={() => {
                      if (launchDetail &&
                        launchDetail.end_meeting_disable == 0) {
                        launchVClass(launchDetail.id, 3, false)
                        return
                      }
                      if (launchDetail && launchDetail.end_campus_disable == 0) {
                        (launchDetail.class_type == "hybrid" ||  launchDetail.class_type == "bbb_class") && launchVClass(launchDetail.id, 3, false) 
                        // : launchCampusClass("end")
                      }
                    }}
                  >
                    <i className="fal fa-window-close"></i> End Class
                  </button>
                )}
              </div>
            ) : ""}
            {launchDetail.status === "Past" && launchDetail.subStatus === "Ended" ? <>
              <button
                className="btn btn-success btn-sidebar mr-2 mt-2"
                title="Class Analytics"
                disabled={analysisBtn.disable}
                onClick={() => window.open(analysisBtn.link, "_blank")}
              >
                <i className="fal fa-analytics"></i> Class Analytics
              </button>
              <button
                className="btn btn-primary btn-sidebar mr-2 mt-2"
                title="Class Attendance"
                onClick={() => window.open(`/classReport/summary/${onlineClass.course_id}/${onlineClass.class_id}`, "_blank")}
              >
                <i className="fal fa-users"></i> Class Attendance
              </button>
              {launchDetail.viewRecording?.status === "YES" ? <a
                className="btn btn-primary btn-sidebar mr-2 pt-1 mt-2"
                title="Watch Video"
                href={launchDetail.viewRecording?.link}
                target="_blank"
                download
              >
                <i className="fal fa-play"></i> Watch Video
              </a> : <button
                className="btn btn-primary btn-sidebar mr-2 pt-1 mt-2"
                title="Watch Video"
                disabled
              >
                <i className="fal fa-play"></i> Watch Video
              </button>}
              <button
                className="btn btn-primary btn-sidebar mr-2 mt-2"
                title="Class Files"
                onClick={() => {
                  Swal.fire({
                    title: "Loading",
                    onOpen: function () {
                      Swal.showLoading();
                    },
                  });
                  getClassFiles({"big_blue_button_id": launchDetail.big_blue_button_id}).then(res => {
                    let arr = res.data.data.Contents || [];
                    let Name = res.data.data.Name;
                    if (arr.length) {
                      arr.forEach(item => {
                        const aTag = document.createElement("a");
                        aTag.target = "_blank"
                        aTag.href = `https://${Name}/${item.Key}`;
                        aTag.setAttribute("download", item.Key);
                        document.body.appendChild(aTag);
                        aTag.click();
                      })
                      Swal.close();
                    } else {
                      Swal.close();
                      Swal.fire("No Files", "There are no Files Uploaded in this class", "success");
                    }
                  }).catch(err => {
                    Swal.close();
                    console.error("error: ", err)
                    Swal.fire("Error!", "Something Went Wrong", "error");
                  })
                }}
              >
                <i className="fal fa-file"></i> Class Files
              </button>
            </> : <>
              <button
                className="btn btn-primary mr-2 mt-2"
                title="System Check"
                onClick={() => window.open("https://test.8x8.vc/", "_blank")}
                disabled={launchDetail.launch_show == 0}
              >
                <i className="fal fa-check-double"></i> System Check
              </button>
              <button
                className="btn btn-primary btn-orange mr-2 mt-2"
                title="Speed Test"
                onClick={() => window.open("https://speedtest.mybroadband.co.za/", "_blank")}
              >
                <i className="fal fa-tachometer-slowest"></i> Speed Test
              </button>
            </>}



            {/* {launchDetail.launch_show == 1 && (
              <div
                className={
                  "box-style-new2 mb-2 " +
                  ((launchDetail.launch_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")
                    ? ""
                    : " bg-sidebar")
                }
              >
                {!loader.includes(1) && (
                  <button
                    // className={
                    //   (launchDetail.launch_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled")) &&
                    //   "dashboard_hover"
                    // }
                    // href="javascript:;"
                    className="btn btn-success mr-2"
                    disabled={((launchDetail.launch_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled"))}
                    onClick={() => {
                      if (launchDetail && (launchDetail.launch_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled"))) {
                        launchVClass(launchDetail.id, 1)
                      }
                    }}
                  >
                    Launch Virtual Class
                  </button>
                )}
              </div>
            )}
            {launchDetail.on_campus_show == 1 && (
                <div
                  className={
                    "box-style-new2" +
                    ((launchDetail.on_campus_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")
                      ? ""
                      : " bg-grn")
                  }
                >
                  {!loader.includes(2) && (
                    <button
                      // className={
                      //   (launchDetail.on_campus_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled")) &&
                      //   "dashboard_hover"
                      // }
                      // href="javascript:;"
                      className="btn btn-success mr-2"
                      disabled={((launchDetail.on_campus_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled"))}
                      onClick={() => {
                        if (launchDetail && (launchDetail.on_campus_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled"))) {
                          launchDetail.class_type == "hybrid"
                            ? launchVClass(launchDetail.id, 1)
                            : launchCampusClass("start")
                        }
                      }}
                    >
                      Launch On Campus Class
                    </button>
                  )}
                </div>
              )}

            {launchDetail.end_meeting_show == 1 && (
                <div
                  className={
                    "box-style-new2 mb-2 " +
                    (launchDetail.end_meeting_disable == 1
                      ? ""
                      : " bg-sidebar")
                  }
                >
                  {console.log("kasjldkf", launchDetail.end_meeting_disable)}
                  {!loader.includes(3) && (
                    <button
                      // className={
                      //   launchDetail.end_meeting_disable == 0 &&
                      //   "dashboard_hover"
                      // }
                      className="btn btn-danger mr-2"
                      disabled={launchDetail.end_meeting_disable == 1}
                      onClick={() => {
                        if (launchDetail &&
                          launchDetail.end_meeting_disable == 0) {
                          launchVClass(launchDetail.id, 3, false)
                        }
                      }}
                    >
                        End Virtual Class
                    </button>
                  )}
                </div>
              )}
            {launchDetail.end_campus_show == 1 && (
                <div
                  className={
                    "box-style-new2" +
                    (launchDetail.end_campus_disable == 1
                      ? ""
                      : " bg-grn")
                  }
                >
                  {!loader.includes(4) && (
                    <button
                      // className={
                      //   launchDetail.end_campus_disable == 0 &&
                      //   "dashboard_hover"
                      // }
                      className="btn btn-danger mr-2"
                      disabled={launchDetail.end_campus_disable == 1}
                      onClick={() => {
                        if (launchDetail && launchDetail.end_campus_disable == 0) {
                          launchDetail.status == "hybrid" ? launchVClass(launchDetail.id, 3, false) : launchCampusClass("end")
                        }
                      }}
                    >
                        End OnCampus Class
                    </button>
                  )}
                </div>
              )} */}
            {/* {(launchDetail.launch_show == 1 || launchDetail.on_campus_show == 1 || (launchDetail.launch_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled")) || (launchDetail.on_campus_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled"))) ? <button
              className="btn btn-success mr-2"
              title="Launch Class"
              // disabled={launchDetail.status == "Past" || launchDetail.status == "Cancelled"}
              onClick={() => {
                if (launchDetail && (launchDetail.launch_disable == 0)) {
                  launchVClass(launchDetail.id, 1, true)
                  return
                }
                if (launchDetail && (launchDetail.on_campus_disable == 0)) {
                  launchDetail.class_type == "hybrid"
                    ? launchVClass(launchDetail.id, 1, true)
                    : launchCampusClass("start")
                }
              }}
            >
              <i className="fal fa-rocket"></i> Launch Class
            </button> : "no launch button"}
            {((launchDetail.end_meeting_show == 1 || launchDetail.end_campus_show == 1) &&  (launchDetail.end_meeting_disable == 0 || launchDetail.end_campus_disable == 0)) ? <button
              className="btn btn-danger mr-2"
              title="End Class"
              // disabled={launchDetail.status == "Past" || launchDetail.status == "Cancelled"}
              onClick={() => {
                if (launchDetail.end_meeting_disable == 0) {
                  launchVClass(launchDetail.id, 3, false)
                  return
                }
                if (launchDetail.end_campus_disable == 0) {
                  launchDetail.class_type == "hybrid" ? launchVClass(launchDetail.id, 3, false) : launchCampusClass("end")
                }
              }}
            >
              <i className="fal fa-window-close"></i> End Class
            </button> : "no end button"} */}
            {/* TODO check join as guest button */}
            {/* if (launchDetail.launch_show == 0) {
    join as guest button
} */}
            {/* {launchDetail && launchDetail.launch_show == 1 && (
              <div
                className={
                  "box-style-new2 mb-2 " +
                  ((launchDetail &&
                    launchDetail.launch_disable == 1) || (launchDetail.status === "Past" || launchDetail.status === "Cancelled")
                    ? ""
                    : " bg-sidebar")
                }
              >
                {!loader.includes(1) && (
                  <a
                    className={
                      (launchDetail.launch_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled")) &&
                      "dashboard_hover"
                    }
                    href="javascript:;"
                    onClick={() => {
                      if (launchDetail && (launchDetail.launch_disable == 0 && (launchDetail.status !== "Past" && launchDetail.status !== "Cancelled"))) {
                        launchVClass(launchDetail.id, 1)
                      }
                    }}
                  >
                    <i className="fal fa-house-signal"></i>
                    <h4>Launch Virtual Class</h4>
                  </a>
                )}
                {loader.includes(1) && (
                  <a
                    href="javascript:;"
                    className="d-block text-center"
                  >
                    <i className="fas fa-cog fa-spin"></i>
                  </a>
                )}
              </div>
            )} */}
            {/* {launchDetail.subStatus === "Launched" ? <> */}
            {/* <button
                className="btn btn-success mr-2"
                title="Launch Class"
                disabled={launchDetail.status == "Past" || launchDetail.status == "Cancelled"}
                onClick={() => {
                  if (launchDetail && (launchDetail.launch_disable == 0)) {
                    launchVClass(launchDetail.id, 1, true)
                    return
                  }
                  if (launchDetail && (launchDetail.on_campus_disable == 0)) {
                    launchDetail.class_type == "hybrid"
                      ? launchVClass(launchDetail.id, 1, true)
                      : launchCampusClass("start")
                  }
                }}
              >
                <i className="fal fa-rocket"></i> Launch Class
              </button>
              <button
                className="btn btn-danger mr-2"
                title="End Class"
                disabled={launchDetail.status == "Past" || launchDetail.status == "Cancelled"}
                onClick={() => {
                  if (launchDetail.end_meeting_disable == 0) {
                    launchVClass(launchDetail.id, 3, false)
                    return
                  }
                  if (launchDetail.end_campus_disable == 0) {
                    launchDetail.class_type == "hybrid" ? launchVClass(launchDetail.id, 3, false) : launchCampusClass("end")
                  }
                }}
              >
                <i className="fal fa-window-close"></i> End Class
              </button> */}
            {/* <button
                className="btn btn-primary mr-2"
                title="System Check"
                onClick={() => window.open("https://test.8x8.vc/", "_blank")}
                disabled={launchDetail.launch_show == 0}
              >
                <i className="fal fa-check-double"></i> System Check
              </button>
              <button
                className="btn btn-primary btn-orange mr-2"
                title="Speed Test"
                onClick={() => window.open("https://speedtest.mybroadband.co.za/", "_blank")}
              >
                <i className="fal fa-tachometer-slowest"></i> Speed Test
              </button>
            </> : ((launchDetail.launch_show == 1 || launchDetail.on_campus_show == 1) && (launchDetail.status == "Live" && (launchDetail.subStatus === "Ended" || launchDetail.subStatus === "Not Launched")) || launchDetail.status == "Upcoming") ? <> */}
            {/* <button
                className="btn btn-success mr-2"
                title="Launch Class"
                disabled={launchDetail.status == "Past" || launchDetail.status == "Cancelled"}
                onClick={() => {
                  if (launchDetail && (launchDetail.launch_disable == 0)) {
                    launchVClass(launchDetail.id, 1, true)
                    return
                  }
                  if (launchDetail && (launchDetail.on_campus_disable == 0)) {
                    launchDetail.class_type == "hybrid"
                      ? launchVClass(launchDetail.id, 1, true)
                      : launchCampusClass("start")
                  }
                }}
              >
                <i className="fal fa-rocket"></i> Launch Class
              </button> */}
            {/* <button
                className="btn btn-primary mr-2"
                title="System Check"
                onClick={() => window.open("https://test.8x8.vc/", "_blank")}
                disabled={launchDetail.launch_show == 0}
              >
                <i className="fal fa-check-double"></i> System Check
              </button>
              <button
                className="btn btn-primary btn-orange mr-2"
                title="Speed Test"
                onClick={() => window.open("https://speedtest.mybroadband.co.za/", "_blank")}
              >
                <i className="fal fa-tachometer-slowest"></i> Speed Test
              </button>
            </> : launchDetail.subStatus === "Ended" ? <>
              <button
                className="btn btn-success btn-sidebar mr-2"
                title="Class Analytics"
                disabled={analysisBtn.disable}
                onClick={() => window.open(analysisBtn.link, "_blank")}
              >
                <i className="fal fa-analytics"></i> Class Analytics
              </button>
              <button
                className="btn btn-primary btn-sidebar mr-2"
                title="Class Attendance"
                onClick={() => window.open(`/classReport/summary/${onlineClass.course_id}/${onlineClass.class_id}`, "_blank")}
              >
                <i className="fal fa-users"></i> Class Attendance
              </button>
              <a
                className="btn btn-primary btn-sidebar mr-2 pt-1"
                title="Watch Video"
                href={
                  ROOT_URL_AWS +
                  "presentation_video/" +
                  launchDetail.big_blue_button_id +
                  "/video.mp4"
                }
                target="_blank"
                download
              >
                <i className="fal fa-play"></i> Watch Video
              </a>
              <button className="btn btn-primary btn-sidebar mr-2" title="Class Files" onClick={() => {// TODO
              }}><i className="fal fa-file"></i> Class Files</button>
            </> : ""} */}
            {launchDetail.subStatus === "Launched" ? <div className={"box-style-css box-style-new3 active d-flex mb-0 align-items-center " + (launchDetail.status === "Past" ? "" : "mt-2")}>
              <h4 className="mb-0 mr-1">
                <i className="fal fa-user-check"></i> Join as Guest&nbsp;
                <i className="fal fa-link"
                  disabled={launchDetail.launch_show == 0}
                  role={launchDetail.launch_show != 0 && "button"}
                  onClick={() => joinAsAdmin("copy")}
                >
                </i>
              </h4>
              <div className="input-box-style-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder=""
                  disabled={launchDetail.launch_show == 0}
                  value={launchDetail.lerner ?? ""}
                  onChange={(e) => setLaunchDetail({ ...launchDetail, lerner: e.target.value ?? "" })}
                />
                <button
                  disabled={launchDetail.launch_show == 0}
                  className="btn btn-submit"
                  onClick={() => joinAsAdmin("guest")}
                >
                  <i className="fal fa-chevron-right"></i>
                </button>
              </div>
            </div> : ""}
          </div>
          <div className="launcher-info-bar d-flex py-3 mt-3">
            {launchDetail.courseName ? <div className="launcher-info-bar-in mr-3">
              <p className="mb-1"><b>Courses</b></p>
              <Link to={`/courseAdministration/coursesdetails/${launchDetail.intakeID}/detailCourses/show`} className="as-text-blue curser">{launchDetail.courseName}</Link>
            </div> : ""}
            {launchDetail.lecture_name ? <div className="launcher-info-bar-in mr-3">
              <p className="mb-1"><b>Lecturer</b></p>
              <UserColumn
                firstName={launchDetail.lecturer.Firstname}
                lastName={launchDetail.lecturer.Lastname}
                imgUrl={launchDetail.lecturer.picture_me}
                email={launchDetail.lecturer.Email}
                mobile={launchDetail.lecturer.Mobile}
                userId={launchDetail.lecturer.UserID}
                userActiveStatus={launchDetail.lecturer.Status}
                isPopUpRight={true}
              />
            </div> : ""}
            {launchDetail.multiple_lecture?.length ? <div className="launcher-info-bar-in mr-3">
              <p className="mb-1"><b>Guest Lecturers</b></p>
              {showMore ? <div>
                <div className="assigned-profile-tit">{launchDetail.multiple_lecture.length ? launchDetail.multiple_lecture.map((data, index) => <UserColumn
                  firstName={data.get_lecture_details?.Firstname}
                  lastName={data.get_lecture_details?.Lastname}
                  imgUrl={data.get_lecture_details?.picture_me}
                  email={data.get_lecture_details?.Email}
                  mobile={data.get_lecture_details?.Mobile}
                  userId={data.get_lecture_details?.UserID}
                  userActiveStatus={data.get_lecture_details?.Status}
                  isUserNameHidden={launchDetail.multiple_lecture.length === 1 ? false : !showMore}
                  isPopUpRight={true}
                  key={index}
                />) : "-"}</div>
                {launchDetail.multiple_lecture.length && launchDetail.multiple_lecture.length > 1 ? <div
                  className="table-expand-btn"
                  onClick={() => setShowMore(!showMore)}>
                  See Less
                </div> : ""}
              </div> : <div className="assigned-profile-overlay-out">
                <div className="assigned-profile-overlay">{launchDetail.multiple_lecture.length ? launchDetail.multiple_lecture.map((data, index) => <UserColumn
                  firstName={data.get_lecture_details?.Firstname}
                  lastName={data.get_lecture_details?.Lastname}
                  imgUrl={data.get_lecture_details?.picture_me}
                  email={data.get_lecture_details?.Email}
                  mobile={data.get_lecture_details?.Mobile}
                  userId={data.get_lecture_details?.UserID}
                  userActiveStatus={data.get_lecture_details?.Status}
                  isUserNameHidden={launchDetail.multiple_lecture.length === 1 ? false : !showMore}
                  isPopUpRight={true}
                  key={index}
                />) : "-"}</div>
                {launchDetail.multiple_lecture.length && launchDetail.multiple_lecture.length > 1 ? <div
                  className="table-expand-btn"
                  onClick={() => setShowMore(!showMore)}>
                  See More
                </div> : ""}
              </div>}
            </div> : ""}
          </div>
          {
            launchDetail.breakoutRooms && launchDetail.breakoutRooms.length>0 ? 
            <div  className={`custom-table-div filter-search-icon card-table-custom days-overdue-cell-width`} style={{paddingTop: "20px"}} > 
              <p className="mb-1 color-black"><b>Breakout Rooms</b></p>
            <DataTable
            // rowsPerPageOptions={[10, 15, 20, 25, 100]}
            progressPending={launchLoading}
            progressComponent={<SkeletonTicketList />}
            data={breakoutRoomList}
            columns={breakoutColumns}
            defaultSortAsc={true}
            defaultSortField="class_title"
            // onSort={handleSort}
            
            highlightOnHover={false}
            paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
            
            
          />

            </div> : ""
          }
         

          {launchDetail.class_description ? <div className={`${launchDetail.big_blue_button_id ? "launcher-desc" : ""} py-3`}>
            {launchDetail.class_description}
          </div> : ""}
          {launchDetail.big_blue_button_id ? <div className="pt-3 ">
            <b>BBB: {(launchDetail?.big_blue_button_id + (launchDetail?.bbbname && ` - ${launchDetail?.bbbname}`))} <CopyToClipboard data={(launchDetail?.big_blue_button_id + (launchDetail?.bbbname && ` - ${launchDetail?.bbbname}`))} /></b>
          </div> : ""}
        </div> : <span className="p-30px"><SkeletonTicketList /></span>}
      />
      <ClassSeriesPopup
        popId="editModal"
        heading="Edit Class"
        message="This is one class in a series. What do you want to open?"
        onSubmit={(val) => {
          history.push(`/courseAdministration/coursesdetails/${id}/onlineClasses/${classId}/open/${recurring}/${val}`)
        }}
      />
      <ClassSeriesPopup
        popId="unlinkClass"
        heading="Unlink Class"
        message="This is one class in a series. What do you want to unlink?"
        onSubmit={(val) => {
          DeleteOnlineClass({ ...popUpState, isRecurring: val })
            .then((data) => {
              getOnlineClassesList();
              Swal.fire("Unlinked!", "Class unlinked successfully.", "success");
            })
            .catch((error) => {
              console.log("error ===> ", error);
            });
        }}
      />
      <ClassSeriesPopup
        popId="cancelClass"
        heading="Cancel Class"
        message="This is one class in a series. What do you want to cancel?"
        onSubmit={(val) => {
          DeleteOnlineClass({ ...popUpState, isRecurring: val })
            .then((data) => {
              getOnlineClassesList();
              Swal.fire("cancelled!", "Class cancelled successfully.", "success");
            })
            .catch((error) => {
              console.log("error ===> ", error);
            });
        }}
      />
      <ClassSeriesPopup
        popId="deleteClass"
        heading="Delete Class"
        message="This is one class in a series. What do you want to delete?"
        onSubmit={(val) => {
          DeleteOnlineClass({ ...popUpState, isRecurring: val })
            .then((data) => {
              getOnlineClassesList();
              Swal.fire("Deleted!", "Class deleted successfully.", "success");
            })
            .catch((error) => {
              console.log("error ===> ", error);
            });
        }}
      />
    </>
  );
};

export default OnlineClassesTable;
