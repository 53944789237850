import React, { useEffect, useMemo, useState } from "react";
import DataTable from "react-data-table-component";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { QuizStatusRender } from "../../../systemadministration/usermanagement/CheckRole";
import { DeleteaQuestion, GetListOfQuestions, GetListOfQuestionsCancelToken, ListQuestionFilters, ListQuestionFiltersCancelToken, QuestionDetails, QuestionDetailsCancelToken } from "../../../../services/SmartAssessmentService";
import Str from "../../../common/Str";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import { TrimText } from "../../../common/TrimText";
import { formatQuestion } from "../../../../utils/FormatQuestion";
import { Link } from "react-router-dom";
import DisplayQuestion from "../DisplayQuestion";
import hasPermission from "../../../../utils/hasMultiplePermission";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../../utils/permissionGate";
import $ from "jquery"
import { TABLE_ROWS_PER_PAGE, TABLE_DATE_FORMAT} from "../../../../utils/Constants";
import { RenderQuestionLevel, RenderQuestionType } from "../../../../utils/CommonGroupingItem";
import Tablefilter from "../../../common/Tablefilter";
import axios from "axios";
import { handleTableScroll } from "../../../../utils/commonFunction";
import { RenderDate, RenderQuizTopic } from "../../../../utils/CommonStatusItems";

var striptags = require('striptags');

const QuestionBank = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const [type, setType] = useState({ arr: [], checkObj: {} });
  const [level, setLevel] = useState({ arr: [], checkObj: {} });
  const [qdata, setQData] = useState();
  const [loading, setLoading] = useState(false);
  const [dummy, setDummy] = useState(true);
  const [questionLoading, setQuestionLoading] = useState(false);
  const [questionData, setquestionData] = useState({});

  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [sortkey, setSortKey] = useState("last_modified_date");
  const [sortOrder, setSortOrder] = useState("DESC");
  const [page, setPage] = useState(1);
  const [debouncedTerm, setDebouncedTerm] = useState(search);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const [topics, setTopics] = useState({ arr: [], checkObj: {} });
  const [filterData, setFilterData] = useState({type : [], level : [], topics : [], getMonthlfDate : [], getYearlfDate : []})
  const [getMonthlfDate, setgetMonthlfDate] = useState({ arr: [], checkObj: {} });
  const [getYearlfDate, setgetYearlfDate] = useState({ arr: [], checkObj: {} });
  const [cancelToken, setCancelToken] = useState("")

  useEffect(() => {
    handleTableScroll()
  }, [loading])

  useEffect(() => {
    $(document).ready(function () {
      $(".dropdown-toggle").click(function () {
        $('.rdt_TableCell').css('z-index', 0)
        $(this).parents('.rdt_TableCell').css('z-index', 22)
      });
    });
  })

  useEffect(() => {
    let response = hasPermission({ scopes: ["cquestionbview"], permissions: givenPermsisions });
    if (!response) {
      history.push("/noaccess");
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => setSearch(debouncedTerm), 1000);
    return () => {
      clearTimeout(timer);
    }
  }, [debouncedTerm])

  useEffect(() => {
    const storedFilters = JSON.parse(localStorage.getItem("filterStateQBank"));
    if (storedFilters) {
      setType(storedFilters.type);
      setLevel(storedFilters.level);
      setTopics(storedFilters.topics);
      setgetMonthlfDate(storedFilters.getMonthlfDate);
      setgetYearlfDate(storedFilters.getYearlfDate);
    }
  }, []);
  useEffect(() => {
    const filterState = {
      type,
      level,
      topics,
      getMonthlfDate,
      getYearlfDate
    };
    localStorage.setItem("filterStateQBank", JSON.stringify(filterState));
  }, [type, level, topics, getMonthlfDate, getYearlfDate]);
  const handleSort = (column, sortDirection) => {
    setSortKey(column.sortField);
    setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
  };
  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
  };
  const handlePageChange = (pageNo) => {
    setPage(pageNo);
  };

  useEffect(() => {
    const cancelTokenSources = [];

    const getListOfQuestions = async () => {
      setLoading(true)
  
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
  
      const data = {
        page: page,
        limit: perPage,
        search: search,
        key: sortkey,
        sort: sortOrder,
        exportStatus: false,
        viaLevel: level.arr.length > 0 ? level.arr : [],
        viaType: type.arr.length > 0 ? type.arr : [],
        viaTopics: topics.arr.length > 0 ? topics.arr : [],
        viaMonth: getMonthlfDate.arr.length > 0 ? getMonthlfDate.arr : [],
        viaYear: getYearlfDate.arr.length > 0 ? getYearlfDate.arr : [],
      }
  
      try {
        const res = await GetListOfQuestionsCancelToken(data, source.token);
        setQData(res.data?.data);
        setTotalRows(res.data?.total);
        if (res.status == 200) {
          setLoading(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    }
    getListOfQuestions()

      return () => {
        cancelTokenSources.forEach(source => {
          source.cancel('Component unmounted');
        });
      };
  }, [dummy, page, perPage, search, type, level, sortkey, sortOrder,topics , getMonthlfDate , getYearlfDate]);

  useEffect(() => {
    const dropdownSource = axios.CancelToken.source();
  
    const fetchData = async () => {
      try {
        const res = await ListQuestionFiltersCancelToken(dropdownSource.token);
        setFilterData({...res.data, type : res.data.questionTypeFilters, level : res.data.questionLevelFilter, topics : res.data.topicFilters, getMonthlfDate: res.data.month, getYearlfDate: res.data.year})
        // setFilterData({...res.data, type : res.data.questionTypeFilters, level : res.data.questionLevelFilter, topics : res.data.topicFilters})
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    };
  
    fetchData();
  
    return () => {
      dropdownSource.cancel('Component unmounted');
    };
  }, []);
  

  const handleViewQuestionDetails = (ID) => {
    setQuestionLoading(true)
    setquestionData({});
    const source = axios.CancelToken.source();
    setCancelToken(source)
    QuestionDetailsCancelToken(ID, source.token).then((res) => {
      setquestionData(res.data[0]);
      setQuestionLoading(false)
    }).catch((errors) => {
      console.error("errorrs", errors)
      setQuestionLoading(false)
    })
  }
  
  const wordSpliter = (str) => {
    // const strArr = str?.toString()?.split(",");
    const strArr = Array.isArray(JSON.parse(str)) ? JSON.parse(str) : [];
    const newStrArr = strArr.length ? strArr.map((item, index) => {      
      return item ? <p key={index} className="as-widget mr-1 mt-1" style={{color: '#333333', background: '#e6e6e6'}}>{item}</p> : <React.Fragment key={index}>-</React.Fragment>;
    }) : "-";
    return newStrArr;
  }
  
  const wordSpliterTopics = (str) => {
    let htmlOutput = []
    const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr.map(item => {
      filterData.topics.map((val)=>{
        if(val.value == item){
          htmlOutput.push(RenderQuizTopic(val.label,val.color).html)
        }
        return;
      });   
    }) : "-";
    return htmlOutput.length ? htmlOutput : []
  }


  const wordSpliterTopicsExport = (str) => {
    let htmlOutput = []
    const strArr = str && Array.isArray(str.split(",")) ? str.split(",") : [];
    const newStrArr = strArr.length ? strArr.map(item => {
      filterData.topics.map((val)=>{
        if(val.value == item){
          htmlOutput.push(RenderQuizTopic(val.label,val.color).text)
        }
        return;
      });   
    }) : "-";
    return htmlOutput.length ? htmlOutput.join(",") : []
  }

  // Function to extract months and return as objects with value and label
  function extractMonths(dateArray) {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const monthsArray = [];
    dateArray.sort((a, b) => a.value - b.value);
    dateArray.forEach((monthObj) => {
        const month = monthObj.value;
        const monthName = monthNames[month - 1];
        monthsArray.push({
            value: month,
            label: monthName,
        });
    });
  
    return monthsArray;
  }

  const columns = useMemo(() => [
    {
      name: "Name",
      selector: "question_name",
      // sortFunction: sortArray,
      sortField: "question_name",
      sortable: true,
      cell: (row, index) => {
        // let question = formatQuestion(row.question);
        return (
          <Link
            className="as-text-blue curser"
            title={row.question_name ? striptags(row.question_name) : "-"}
            // title={row.question_name ? row.question_name.replace(/(<([^>]+)>)/gi, "").trim(" ") : "-"}
            to={
              "/courseAdministration/smartAssessment/questionBank/question/view/" +
              row.qid
            }
          >
            <p className="textLimit150"> {row.question_name ? formatQuestion(row.question_name) : "-"} </p>
            {/* {row.question.toString() ? TrimText(row.question, 30) : "-"} */}
          </Link>
        );
      },
    },
    {
      name: "Type",
      selector: "question_type",
      sortField: "question_type",
      sortable: true,
      cell: (row, index) => (
        <>
          {row.question_type ? RenderQuestionType(row.question_type.trim()).html : "-"}
        </>
      ),
    },
    {
      name: "Status",
      selector: "status",
      sortField: "status",
      sortable: true,
      cell: (row, index) => (
        <>
          {row.status ? QuizStatusRender(row) : "-"}
        </>
      ),
    },
    {
      name: "Level",
      selector: "level_name",
      sortField: "level_name",
      sortable: true,
      cell: (row, index) =>
        row.level_name ? RenderQuestionLevel(row.level_name).html : "-",
    },
    {
      name: "NQF Level",
      selector: "nqf_level",
      sortField: "nqf_level",
      sortable: true,
      cell: (row, index) =>
        row.nqf_level ? <span>NQF {row.nqf_level.replace("NQF ","")}</span>: "-",
    },
    {
      name: "#Linked Quizzes",
      selector: "AssigendToassessmentCount",
      sortField: "AssigendToassessmentCount",
      sortable: true,
      cell: (row, index) => (
        <p>
          {row.AssigendToassessmentCount.toString()
            ? row.AssigendToassessmentCount
            : "-"}
        </p>
      ),
    },
    {
      name: "Keywords",
      cell: (row) => row?.keywords ? <div className="d-flex flex-wrap">{wordSpliter(row?.keywords)}</div> : '-',
    },
    {
      name: "Topics",
      cell: (row) => row?.topic ? <div className="d-flex flex-wrap">{wordSpliterTopics(row.topic).length ? wordSpliterTopics(row.topic).map((item, index) => <React.Fragment key={index}>{item} &nbsp;</React.Fragment>) : ""}</div> : '-',
    },
    {
      name: "Last Modified Date",
      selector: "last_modified_date",
      sortField: "last_modified_date",
      sortable: true,
      cell: (row) => {
        const moment = require('moment');
        // Check if the last_modified_date exists
        if (row.last_modified_date) {
          // Use Moment.js to format the date
          const formattedDate = moment(row.last_modified_date).format(TABLE_DATE_FORMAT).toString() === "Invalid date" ? "-" : moment(row.last_modified_date).format(TABLE_DATE_FORMAT);
      
          return formattedDate;
        } else {
          return "-";
        }
      },
    },
    {
      name: "Actions",
      maxWidth: "50px",
      cell: (row, index) => (
        <div className="assessment-08 btn-dropdown-grp">
          <div className="as-buttons d-flex">
            <Link
              className="btn btn-primary rounded-circle"
              title="Open"
              to={
                "/courseAdministration/smartAssessment/questionBank/question/view/" +
                row?.qid
              }
            >
              <i className="fal fa-folder-open"></i>
            </Link>
            <div className="dropdown btn-dropdown-item ml-1">
              <button
                className="btn btn-primary rounded-circle dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                title="More"
              >
                <i className="fal fa-ellipsis-h-alt"></i>
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <PermissionsGate scopes={["cquestionbadd"]} RenderError={() => (
                  <button
                    className="btn btn-primary rounded-circle ml-1"
                    title="Duplicate"
                    disabled
                  >
                    <i className="fal fa-copy"></i>
                  </button>
                )
                }>

                  {row?.question_type === "Long Answer" ?
                    <button
                    className="btn btn-primary rounded-circle ml-1"
                    title="Duplicate"
                    disabled
                    >
                      <i className="fal fa-copy"></i>
                    </button>
                    :<Link
                    className="btn btn-primary rounded-circle ml-1"
                    title="Duplicate"
                    to={"/courseAdministration/smartAssessment/questionBank/question/duplicate/" + row?.qid}
                  >
                    <i className="fal fa-copy"></i>
                  </Link>}
                </PermissionsGate>
                <button
                  className="btn btn-primary rounded-circle"
                  data-toggle="modal"
                  data-target="#question"
                  title="View Question"
                  onClick={() => {
                    handleViewQuestionDetails(row.qid)
                  }}
                >
                  <i className="fal fa-eye"></i>
                </button>
                <PermissionsGate scopes={["cquestionbdelete"]} errorProps={{ disabled: true }}>
                  <button
                    className="btn btn-danger rounded-circle"
                    title="Delete"
                    onClick={() => handleDelete(row.qid)}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
      ),
    },
  ], [filterData]);

  const handleDelete = (ID) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        DeleteaQuestion({ questionId: ID })
          .then((res) => {
            Swal.fire("Deleted!", "Your question has been deleted.", "success");
            setDummy(!dummy);
          })
          .catch((error) => console.log(error));
      }
    });
  };

  function resetFilter() {
    setSearch("");
    setType({ arr: [], checkObj: {} });
    setLevel({ arr: [], checkObj: {} });
    setDebouncedTerm("");
    setTopics({ arr: [], checkObj: {} });
    setgetMonthlfDate({ arr: [], checkObj: {} });
    setgetYearlfDate({ arr: [], checkObj: {} });
  }

  const exportData = async (fileType, fileName) => {
    let data;

    const header = ["Name", "Type", "Status", "Level", "NQF Level", "#Linked Quizzes", "Last Modified Date", "Keywords", "Topics"];

    Swal.fire({
      title: "File downloading",
      onOpen: function () {
        Swal.showLoading();
      },
    });


    GetListOfQuestions({
      page: page,
      limit: perPage,
      search: search,
      key: sortkey,
      sort: sortOrder,
      exportStatus: true,
      viaLevel: level.arr.length > 0 ? level.arr : [],
      viaType: type.arr.length > 0 ? type.arr : [],
      viaTopics: topics.arr.length > 0 ? topics.arr : [],
    })
      .then((res) => {
        data = res?.data
        data = data?.map((row) => ({
          ...row,
          Name: row.question_name ? TrimText(row.question_name, 20) : "-",
          Type: row.question_type ? RenderQuestionType(row.question_type).text : "-",
          Status: row.status ? row.status : "-",
          Level: row.level_name ? RenderQuestionLevel(row.level_name).text : "-",
          "NQF Level": row.nqf_level ? `NQF ${row.nqf_level.replace("NQF ","")}` : "-",
          "#Linked Quizzes": row.AssigendToassessmentCount?.toString() ? row.AssigendToassessmentCount : "-",
          lastModifiedDate: row.last_modified_date? row.last_modified_date: "-", 
          Keywords: (row.keywords && row.keywords.length > 0 && Array.isArray(JSON.parse(row.keywords))) ? JSON.parse(row.keywords)?.map(item=>item+" ").join(", ") : "-",
          Topics: (row.topic && wordSpliterTopicsExport(row.topic).length) ? wordSpliterTopicsExport(row.topic) : "-" ,
        }));
        if (fileType === "csv") {
          const csvString = Papa.unparse({ fields: header, data });
          const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

          const blobURL = window.URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.download = fileName;
          anchor.href = blobURL;
          anchor.dataset.downloadurl = [
            "text/csv",
            anchor.download,
            anchor.href,
          ].join(":");
          anchor.click();

          setTimeout(() => {
            URL.revokeObjectURL(blobURL);
          }, 1000);
          Swal.close();
        } else if (fileType === "xlsx") {
          const compatibleData = data.map((row) => {
            const obj = {};
            header.forEach((col, index) => {
              obj[col] = row[col];
            });
            return obj;
          });

          let wb = XLSX.utils.book_new();
          let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
            header,
          });
          XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
          XLSX.writeFile(wb, `${fileName}.xlsx`);
          Swal.close();
          return false;
        }
        if (fileType === "pdf") {
          const compatibleData = data.map((row) => {
            return [
              row["Name"],
              row["Type"],
              row["Status"],
              row["Level"],
              row["NQF Level"],
              // row["% Correct"],
              row["#Linked Quizzes"],
              //row["Last Modified Date"],
              row["Last Modified Date"],
              row["Keywords"],
              row["Topics"],
            ];
          });
          const doc = new JsPDF();
          doc.autoTable({
            head: [header],
            body: compatibleData,
            styles: {
              minCellHeight: 10,
              minCellWidth: 5,
              halign: "left",
              fontSize: 8,
            },
            columnStyles: {
              0: {
                columnWidth: 30,
              },
              1: {
                columnWidth: 30,
              },
              2: {
                columnWidth: 20,
              },
              3: {
                columnWidth: 20,
              },
              4: {
                columnWidth: 20,
              },
              5: {
                columnWidth: 20,
              },
              6: {
                columnWidth: 20,
              },
              7: {
                columnWidth: 20,
              },
              8: {
                columnWidth: 20,
              },
            }
          });
          doc.save(`${fileName}.pdf`);
          Swal.close();
          return false;
        }
      })
  };

  function stopAudioAndVideo (event) {
    let modal = document.getElementById("question");
    let element = modal.getElementsByClassName("form-group")
    let elementArr = Array.from(element);
    if (elementArr.length) {
      elementArr[0].remove()
    }
    // console.log("sdfs", modal, iframe)
    // let audioElement = document.getElementById("myAudio");
    // if (audioElement) {
    //   new Audio(audioElement).pause()
    //   console.log("Adsfh", audioElement)
    // }
    // let audioArr = Array.from(audioElement);
    // audioArr.forEach(item => {
    //   item.pause()
    //   console.log("ASfha", item)
    // });

  }

    useEffect(() => {
    $("#question").on("hidden.bs.modal", stopAudioAndVideo)
    // $("#question .close").on("click", stopAudioAndVideo); 
    // return () => {
    //   $("#question .close").onclick(e => console.log("jlsdfg", e)); 
    // }
  }, [])

  return (
    <div className="my-tickets-info-list Tickets-main-wrap tickets-new-custom">
      <div className="custom-table-div filter-search-icon card card-table-custom qus-bank-table">
        <div className="search-filter-div">
          <div className="search-filter-div-left">
            <div className="system-administration-table table-responsive">
              <div className="table-responsive-div">
                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                  <div id="assessment-table-main_filter" className="dataTables_filter">
                    <label>
                      <input
                        type="search"
                        placeholder="Search"
                        aria-controls="assessment-table-main"
                        // onChange={(e) => {
                        //   handleFilter(e, "search");
                        // }}
                        // value={search}
                        onChange={e => setDebouncedTerm(e.target.value)}
                        value={debouncedTerm}
                      />
                    </label>
                    <div className="filter-eff filter-data-btn">
                      <button className="filter-buttons">
                        <i className="fal fa-filter"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-button-group">
                <div className="filter-scroll">
                  <div className={`filter-scroll-inner  filter-custom-new`}>
                    <Tablefilter
                      filterName="Type"
                      optionArr={filterData.type}
                      state={type}
                      setState={setType}
                      renderLabelFunction={RenderQuestionType}
                    />
                    <Tablefilter
                      filterName="Level"
                      optionArr={filterData.level}
                      state={level}
                      setState={setLevel}
                      renderLabelFunction={RenderQuestionLevel}
                    />
                    <Tablefilter
                      filterName="Topic"
                      optionArr={filterData.topics}
                      state={topics}
                      setState={setTopics}
                      renderLabelFunction={RenderQuizTopic}
                      uniqueId = "topic"
                    />
                    <Tablefilter
                      filterName="Month"
                      optionArr={extractMonths(filterData.getMonthlfDate || [])}
                      state={getMonthlfDate}
                      setState={setgetMonthlfDate}
                      stopOptionSorting={true}
                    />
                    <Tablefilter
                      filterName="Year"
                      optionArr={filterData.getYearlfDate || []}
                      state={getYearlfDate}
                      setState={setgetYearlfDate}
                      renderLabelFunction={RenderDate}
                      uniqueId="Date"
                    />
                  </div>
                </div>

                <div className="reset-btn-group">
                  <div className="button-reset dropdown-comman">
                    <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                      <i className="fal fa-redo"></i>Reset
                    </button>
                  </div>

                  <div className="files-export-group">
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("xlsx", "Questions");
                      }}
                      title="Export spreadsheet"
                    >
                      <i className="fal fa-file-excel"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("csv", "Questions");
                      }}
                      title="Export CSV"
                    >
                      <i className="fal fa-file-csv"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-files"
                      onClick={() => {
                        exportData("pdf", "Questions");
                      }}
                      title="Export PDF"
                    >
                      <i className="fal fa-file-pdf"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="search-filter-div-right">
            <div className=" filter-search-bar-blk">
              <div className="add-ticket-blk button-reset dropdown-comman">
                <PermissionsGate scopes={["cquestionbadd"]} RenderError={() => (
                  <button disabled className="btn btn-primary" title="Create New" >
                    <i className="fal fa-plus"></i>Create New
                  </button>
                )}>
                  <Link className="btn btn-primary" to={"/courseAdministration/smartAssessment/addquestion"}>
                    <i className="fal fa-plus"></i>Create New
                  </Link>
                </PermissionsGate>
              </div>
            </div>
          </div>
        </div>
        <DataTable
          data={qdata}
          defaultSortField="last_modified_date"
          defaultSortAsc={false}
          columns={columns}
          pagination={true}
          noDataComponent={Str.noRecord}
          progressPending={loading}
          progressComponent={<SkeletonTicketList />}
          onSort={handleSort}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          highlightOnHover={false}
          paginationServer
          paginationTotalRows={totalRows}
          paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
        />
      </div>
      {/* <DisplayQuestion questionId={questionId} /> */}
      <div
        className="topic-add-modal modal fade"
        id="question"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >

          <div className="modal-content modal-border-update">
            <div className="modal-body p-0">

              <div className="modal-header modal-header-custom">
                <h5 className="modal-title">
                  View Question
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    // formik.resetForm()
                    cancelToken.cancel('Component unmounted');
                    setCancelToken("")
                    $("#question").modal("hide");
                    // setEditorState1("");
                    // setShowCkEditor(false);

                    // formik.setFieldValue("type", "");
                  }}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div className="t p-30px">
                <DisplayQuestion question={questionData} from="questionBank" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuestionBank;
