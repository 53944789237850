const initialState = {
  assessment_name: "",
  assessement_type:''
};

const assessmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_ASSESSMENT_NAME":
      state.assessment_name = action.payload;
      return { ...state,assessment_name: state.assessment_name };
    case 'UPDATE_ASSESSEMENT_TYPE':
      state.assessement_type = action.payload;
      return {...state, assessement_type : state.assessement_type }
    default:
      return state;
  }
};

export default assessmentReducer;
