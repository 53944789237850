import React, { useState, useMemo, useEffect } from "react";
import DataTable from "react-data-table-component";
import "jspdf-autotable";
import JsPDF from "jspdf";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";
import SkeletonTicketList from "../../../../loaders/SkeletonTicketList";
import { getQuiz, GetQuizFilter, GetQuizTopics } from "../../../../services/SmartAssessmentService";
import Str from "../../../common/Str";
import { TABLE_ROWS_PER_PAGE, TABLE_DATE_FORMAT } from "../../../../utils/Constants";
import { QuizStatusRender } from "../../../systemadministration/usermanagement/CheckRole";
import PermissionsGate from "../../../../utils/permissionGate";
import { Link, useLocation } from "react-router-dom";
import Tablefilter from "../../../common/Tablefilter";
import { RenderDate, RenderQuizStatus, RenderQuizTopic, RenderQuizType } from "../../../../utils/CommonStatusItems";
import { handleTableScroll } from "../../../../utils/commonFunction";

const AddQuizzTable = ({ addQuizzHandler , AssessmentType , selectedRows , setSelectedRows }) => {
    const { pathname } = useLocation();
    const currentPath = pathname.split("/");

    const [search, setSearch] = useState("");
    const [type, setType] = useState([]);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortkey, setSortKey] = useState("last_modified_date");
    const [sortOrder, setSortOrder] = useState("DESC");
    const [page, setPage] = useState(1);
    // const [selectedRows, setSelectedRows] = useState([]);

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filterData, setFilterData] = useState({status : [], topic : [], type : [], Month : [], Year : [] });
    const [status, setStatus] = useState({ arr: [], checkObj: {} });
    const [topics, setTopics] = useState({ arr: [], checkObj: {} });
    const [Month, setMonth] = useState({ arr: [], checkObj: {} });
    const [Year, setYear] = useState({ arr: [], checkObj: {} });
    const [quizType, setQuizType] = useState({ arr: [], checkObj: {} });

    useEffect(() => {
        handleTableScroll()
      }, [loading])

    useEffect(() => {      
        GetQuizFilter().then((res)=>{
          setFilterData({...res.data, status : res.data.quizStatusFilters, topic : res.data.topicFilters, type : res.data.quizTypeFilter, Month: res.data.month, Year: res.data.year})
        }).catch(err=>console.log(err))
    }, [])
      
    


    useEffect(() => {
        setLoading(true);
        const data = {
            page: page,
            limit: perPage,
            viaSearch: search ? search : "",
            key: sortkey,
            sort: sortOrder,
            exportStatus: false,
            viaStatus: status.arr.length > 0 ? status.arr : [],
            viaTopics: topics.arr.length > 0 ? topics.arr : [],
            viaMonth: Month.arr.length > 0 ? Month.arr : [],
            viaYear: Year.arr.length > 0 ? Year.arr : [],
            is_practical_assessment : AssessmentType == 13 || AssessmentType == 14 ? 1 : 0
            // viaQuizType: quizType.arr.length > 0 ? quizType.arr : [],
        }
        getQuiz(data).then((res) => {
            setData(res.data?.data)
            setTotalRows(res?.data?.total);
            setLoading(false);
        })
    }, [page, perPage, search, sortOrder, sortkey, status, topics, quizType, Month, Year , AssessmentType])

    const wordSpliter = (str, type) => {
        // console.log("str------",str);
        const strArr = Array.isArray(JSON.parse(str)) ? JSON.parse(str) : [];
        const newStrArr = strArr.length ? strArr?.map((item, ind) => {
            return item ? <p key={ind} className="as-widget mr-1 mt-1" style={{ color: '#333333', background: '#e6e6e6' }}>{item}</p> : '-';            
        }) : "-"
        return newStrArr;
    }

    const wordSpliterTopics = (str, type) => {
        // console.log("str------",str);
        const strArr = Array.isArray(str.split(",")) ? str.split(",") : [];
        const newStrArr = strArr.length ? strArr?.map((item, ind) => {
            const topic = filterData.topic.find(val => val?.value == item);
            return item ? <p key={ind} className="as-widget mr-1 mt-1" style={{ color: '#fff', background: topic?.color }}>{topic?.label}</p> : '-';            
        }) : "-"
        return newStrArr;
    }

    function extractMonths(dateArray) {
        const monthNames = [
          'January', 'February', 'March', 'April', 'May', 'June',
          'July', 'August', 'September', 'October', 'November', 'December'
        ];
    
        const monthsArray = [];
        dateArray.sort((a, b) => a.value - b.value);
        dateArray.forEach((monthObj) => {
            const month = monthObj.value;
              const monthName = monthNames[month - 1];
                monthsArray.push({
                  value: month,
                  label: monthName,
                });
        });
      
        return monthsArray;
      }


    const handleSelect = (e, row) => {
        const { checked, id, name, value } = e.target;
        const ls = [...selectedRows];        
        if(checked){
            ls.push(row.quid);
        }else{
            const ind = ls.indexOf(Number(id));
            ls.splice(ind,1);
        }
        setSelectedRows([...ls]);
        addQuizzHandler([...ls]);
    }

    const columns = useMemo(() => [
        {
            name: "Select",
            cell: (row, ind) => {      
              return <input
                type={"checkbox"}
                name="select"
                id={row?.quid}
                onChange={(e) => handleSelect(e, row)}
                checked={selectedRows.includes(row?.quid)}
              />
            }
        },
        {
            name: "Name",
            selector: "quiz_name",
            sortField: "quiz_name",
            sortable: true,
            cell: (row) => (
                <PermissionsGate scopes={["cquizmview"]} errorProps={{ disabled: true }}>
                    <Link 
                    title={row?.quiz_name}
                    className="as-text-blue curser feature-name"
                    to={`/courseAdministration/assessment/quizzes/open/details/${row?.quid}`}>
                        <p className="textLimit150"> {row?.quiz_name} </p>
                    </Link>
                </PermissionsGate>
            )
        },
        // {
        //     name: "Quiz Type",
        //     selector: "quiz_type",
        //     sortField: "quiz_type",
        //     sortable: false,
        //     cell: (row) => (row.quiz_type && filterData.type.length ? RenderQuizType(filterData.type.find(item => item.value==row.quiz_type)).html : "-")
        // },
        {
            name: "#Question",
            selector: "noq",
            sortField: "noq",
            sortable: true,
            cell: (row) => row?.noq
        },
        {
            name: "Status",
            selector: "Status",
            sortField: "Status",
            sortable: true,
            cell: (row) => (QuizStatusRender(row)),
        },
        {
            name: "Keywords",
            // selector: "keywords",
            // sortField: "keywords",
            // sortable: true,
            cell: (row) => row?.keywords ? <div className="d-flex flex-wrap">{wordSpliter(row?.keywords, "keywords")}</div> : '-',
        },
        {
            name: "Last Modified Date",
            selector: "last_modified_date",
            sortField: "last_modified_date",
            sortable: true,
            cell: (row) => {
              const moment = require('moment');
              // Check if the last_modified_date exists
              if (row.last_modified_date) {
                // Use Moment.js to format the date
                const formattedDate = moment(row.last_modified_date).format(TABLE_DATE_FORMAT).toString() === "Invalid date" ? "-" : moment(row.last_modified_date).format(TABLE_DATE_FORMAT);
            
                return formattedDate;
              } else {
                return "-";
              }
            },
          },
        {
            name: "Topics",
            // selector: "topic",
            // sortField: "topic",
            // sortable: true,
            cell: (row) => row?.topic ? <div className="d-flex flex-wrap">{wordSpliterTopics(row?.topic, "topics")}</div> : '-',
        }        
    ]);
    const assessmentColumns = useMemo(() => [      
        // {
        //     name: "Select",
        //     cell: (row, ind) => {
        //         return (
        //             <input
        //                 type={"checkbox"}
        //                 name="select"
        //                 id={row?.quid}
        //                 onChange={(e) => handleSelect(e, row)}
        //                 checked={selectedRows.includes(row?.quid)}
        //             />
        //         );
        //     }
        // },  
        {
            name: "Name",
            selector: "quiz_name",
            sortField: "quiz_name",
            sortable: true,
            cell: (row) => (
                <PermissionsGate scopes={["cquizmview"]} errorProps={{ disabled: true }}>
                    <Link 
                    target={"_blank"}
                    title={row?.quiz_name}
                    className="as-text-blue curser feature-name"
                    to={`/courseAdministration/assessment/quizzes/open/details/${row?.quid}`}>
                        <p className="textLimit150"> {row?.quiz_name} </p>
                    </Link>
                </PermissionsGate>
            )
        },
        // {
        //     name: "Quiz Type",
        //     selector: "quiz_type",
        //     sortField: "quiz_type",
        //     sortable: false,
        //     cell: (row) => (row.quiz_type && filterData.type.length ? RenderQuizType(filterData.type.find(item => item.value==row.quiz_type)).html : "-")
        // },
        {
            name: "#Question",
            selector: "noq",
            sortField: "noq",
            sortable: true,
            cell: (row) => row?.noq
        },
        {
            name: "Status",
            selector: "Status",
            sortField: "Status",
            sortable: true,
            cell: (row) => (QuizStatusRender(row)),
        },
        {
            name: "Keywords",
            // selector: "keywords",
            // sortField: "keywords",
            // sortable: true,
            cell: (row) => row?.keywords ? <div className="d-flex flex-wrap">{wordSpliter(row?.keywords, "keywords")}</div> : '-',
        },
        {
            name: "Topics",
            // selector: "topic",
            // sortField: "topic",
            // sortable: true,
            cell: (row) => row?.topic ? <div className="d-flex flex-wrap">{wordSpliterTopics(row?.topic, "topics")}</div> : '-',
        },
        {
            name: "Last Modified Date",
            selector: "last_modified_date",
            sortField: "last_modified_date",
            sortable: true,
            cell: (row) => {
              const moment = require('moment');
              // Check if the last_modified_date exists
              if (row.last_modified_date) {
                // Use Moment.js to format the date
                const formattedDate = moment(row.last_modified_date).format(TABLE_DATE_FORMAT).toString() === "Invalid date" ? "-" : moment(row.last_modified_date).format(TABLE_DATE_FORMAT);
            
                return formattedDate;
              } else {
                return "-";
              }
            },
        },  
        {
            name: "Actions",
            maxWidth: "50px",
            cell: (row) => (
                <div className="assessment-08 btn-dropdown-grp">
                    <div className="as-buttons d-flex">
                        {selectedRows.includes(row?.quid) ? (
                            <PermissionsGate scopes={["castedit"]} errorProps={{ disabled: true }}>
                                <button
                                    type="button"
                                    className="btn btn-danger rounded-circle"
                                    title="Remove"
                                    onClick={() => addresource(row?.quid, row?.quiz_name, row)}
                                >
                                    <i className="fal fa-minus"></i>
                                </button>
                            </PermissionsGate>
                        ) : (
                            <button
                                type="button"
                                className="btn btn-primary rounded-circle"
                                title="Add"
                                onClick={() => addresource(row?.quid, row?.quiz_name, row)}
                            >
                                <i className="fal fa-plus"></i>
                            </button>
                        )}
                    </div>
                </div>
            ),
        },
    ]);

    const addresource = (id, label, quizData) => {
        if (!selectedRows.includes(id)) {
            Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Add it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    setSelectedRows([...selectedRows, id]);
                    addQuizzHandler(id, label, quizData);
                    Swal.fire({ icon: "success", title: "Success", text: "Added Successfully" });
                }
            });
        } else {
            Swal.fire({
                title: "Are you sure?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Remove it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    setSelectedRows(selectedRows.filter(rowId => rowId !== id));
                    addQuizzHandler("", "", {});
                    Swal.fire({ icon: "success", title: "Success", text: "Removed Successfully" });
                }
            });
        }
    };    

    const handleSearch = (e) => {
        setSearch(e.target.value);        
    };

    const resetFilter = () => {
        setSearch("");
        setStatus({ arr: [], checkObj: {} });
        setTopics({ arr: [], checkObj: {} });
        setQuizType({ arr: [], checkObj: {} });
        setMonth({ arr: [], checkObj: {} });
        setYear({ arr: [], checkObj: {} });
    };

    const handleSort = (column, sortDirection) => {
        setSortKey(column.sortField);
        setSortOrder(sortDirection === "asc" ? "ASC" : "DESC");
    };
    const handlePerRowsChange = (newPerPage, page) => {
        setPerPage(newPerPage);
    };
    const handlePageChange = (pageNo) => {
        setPage(pageNo);
    };

    const exportData = async (fileType, fileName) => {
        let data;
        const header = ["Name", "Question", "Status", "Keywords", "Topics"];
        Swal.fire({
            title: "File downloading",
            onOpen: function () {
                Swal.showLoading();
            },
        });

        getQuiz({
            page: page,
            limit: perPage,
            viaSearch: search ? search : "",
            key: sortkey,
            sort: sortOrder,
            exportStatus: true,
            viaStatus: status.arr.length > 0 ? status.arr : [],
            viaTopics: topics.arr.length > 0 ? topics.arr : [],
            // viaQuizType: quizType.arr.length > 0 ? quizType.arr : [],
        })
            .then((res) => {
                data = res?.data
                data = data?.map((row) => ({
                    ...row,
                    Name: row?.quiz_name ? row?.quiz_name : "-",
                    // "Quiz Type" : row?.quiz_type ? RenderQuizType(filterData.type.find(item => item.value==row.quiz_type)).text : "-",
                    Question: row?.noq ? row?.noq : "-",
                    Status: row.status ? row.status : "-",
                    Keywords: (row?.keywords && row?.keywords.length > 0 && Array.isArray(JSON.parse(row?.keywords))) ? JSON.parse(row?.keywords)?.map(item => item).join(", ") : "-",
                    Topics: (row?.topic && row?.topic.length > 0 && Array.isArray((row?.topic).split(","))) ? (row?.topic.split(","))?.map(item => filterData.topic.find(val => val.value == item)?.label).join(", ") : "-",
                }));
                if (fileType === "csv") {
                    const csvString = Papa.unparse({ fields: header, data });
                    const blob = new Blob([csvString], { type: "text/csv;charset=utf-8," });

                    const blobURL = window.URL.createObjectURL(blob);

                    const anchor = document.createElement("a");
                    anchor.download = fileName;
                    anchor.href = blobURL;
                    anchor.dataset.downloadurl = [
                        "text/csv",
                        anchor.download,
                        anchor.href,
                    ].join(":");
                    anchor.click();

                    setTimeout(() => {
                        URL.revokeObjectURL(blobURL);
                    }, 1000);
                    Swal.close();
                } else if (fileType === "xlsx") {
                    const compatibleData = data.map((row) => {
                        const obj = {};
                        header.map((col, index) => {
                            obj[col] = row[col];
                        });
                        return obj;
                    });

                    let wb = XLSX.utils.book_new();
                    let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
                        header,
                    });
                    XLSX.utils.book_append_sheet(wb, ws1, "React Table Data");
                    XLSX.writeFile(wb, `${fileName}.xlsx`);
                    Swal.close();
                    return false;
                }
                if (fileType === "pdf") {
                    const compatibleData = data.map((row) => {
                        return [
                            row["quiz_name"],
                            row["noq"],
                            row["Status"],
                            row["Keywords"],
                            row["Topics"],
                        ];
                    });
                    const doc = new JsPDF();
                    doc.autoTable({
                        head: [header],
                        body: compatibleData,
                        styles: {
                            minCellHeight: 10,
                            minCellWidth: 5,
                            halign: "left",
                            fontSize: 8,
                        },
                    });
                    doc.save(`${fileName}.pdf`);
                    Swal.close();
                    return false;
                }
            })
    };

    return (
        <div className="modal-body my-tickets-info-list Tickets-main-wrap tickets-new-custom">
            <div className="custom-table-div filter-search-icon card-table-custom">
                <div className="search-filter-div">
                    <div className="search-filter-div-left">
                        <div className="system-administration-table table-responsive">
                            <div className="table-responsive-div">
                                <div id="assessment-table-main_wrapper" className="dataTables_wrapper no-footer">
                                    <div id="assessment-table-main_filter" className="dataTables_filter">
                                        <label>
                                            <input type="search" className="" placeholder="Search" aria-controls="assessment-table-main" onChange={(e) => { handleSearch(e) }} value={search} />
                                        </label>
                                        <div className="filter-eff filter-data-btn">
                                            <button className="filter-buttons">
                                                <i className="fal fa-filter"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-button-group">
                                <div className="filter-scroll">
                                    <div className={`filter-scroll-inner  filter-custom-new`}>
                                    <Tablefilter
                                        filterName="Status"
                                        optionArr={filterData.status}
                                        state={status}
                                        setState={setStatus}
                                        renderLabelFunction={RenderQuizStatus}
                                    />
                                    <Tablefilter
                                        filterName="Topic"
                                        optionArr={filterData.topic}
                                        state={topics}
                                        setState={setTopics}
                                        renderLabelFunction={RenderQuizTopic}
                                        uniqueId = "topic"
                                    />
                                    <Tablefilter
                                        filterName="Month"
                                        optionArr={extractMonths(filterData.Month)}
                                        state={Month}
                                        setState={setMonth}
                                        stopOptionSorting={true}
                                    />
                                    <Tablefilter
                                        filterName="Year"
                                        optionArr={filterData.Year}
                                        state={Year}
                                        setState={setYear}
                                        renderLabelFunction={RenderDate}
                                    />
                                    {/* <Tablefilter
                                        filterName="Quiz Type"
                                        optionArr={filterData.type}
                                        state={quizType}
                                        setState={setQuizType}
                                        renderLabelFunction={RenderQuizType}
                                        uniqueId = "quizType"
                                    /> */}
                                    </div>
                                </div>
                                <div className="reset-btn-group">
                                    <div className="button-reset dropdown-comman">
                                        <button className="btn btn-primary" onClick={resetFilter} title="Reset">
                                            <i className="fal fa-redo"></i>Reset
                                        </button>
                                    </div>
                                    <div className="files-export-group">
                                        <button type="button" className="btn btn-files" title="Export spreadsheet" onClick={() => { exportData("xlsx", "Available Questions"); }}>
                                            <i className="fal fa-file-excel icon"></i>
                                        </button>
                                        <button type="button" className="btn btn-files" title="Export CSV" onClick={() => { exportData("csv", "Available Questions"); }} >
                                            <i className="fal fa-file-csv icon"></i>
                                        </button>
                                        <button type="button" className="btn btn-files" title="Export PDF" onClick={() => { exportData("pdf", "Available Questions"); }}>
                                            <i className="fal fa-file-pdf icon"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DataTable
                    data={data}
                    defaultSortField="last_modified_date"
                    defaultSortAsc={false}
                    columns={currentPath.includes("assemblies") ? columns : assessmentColumns}
                    pagination
                    noDataComponent={Str.noRecord}
                    progressPending={loading}
                    progressComponent={<SkeletonTicketList />}
                    onSort={handleSort}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    highlightOnHover={false}
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationRowsPerPageOptions={TABLE_ROWS_PER_PAGE}
                />
            </div>
        </div>
    );
};

export default AddQuizzTable;