import React, { useState, useEffect, useRef } from 'react'
import { RenderCategoriesType } from '../../utils/CommonGroupingItem'
import { useParams } from 'react-router-dom';
import { RenderSubmissionAndResultStatus } from '../../utils/CommonStatusItems';

function AssessmentDetails({ quizQuestion, startStopData, practicalAssessmentHeader }) {
    const { openTabs , subType} = useParams();

    const [expanded, setExpanded] = useState(false);
    const [showSeeMore, setShowSeeMore] = useState(false);
    const [timeLeft, setTimeLeft] = useState(0);
    const descriptionRef = useRef(null);

    const description = subType == "questionDetails" ? quizQuestion?.description : practicalAssessmentHeader?.details;

    useEffect(() => {
        const checkOverflow = () => {
            if (descriptionRef.current) {
                setShowSeeMore(descriptionRef.current.scrollHeight > descriptionRef.current.clientHeight);
            }
        };

        checkOverflow();

        window.addEventListener('resize', checkOverflow);

        return () => {
            window.removeEventListener('resize', checkOverflow);
        };
    }, [description]);

    const handleToggle = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        if (openTabs !== "assessmentMarking" || startStopData?.is_start_assessment == 0) return;

        // const totalTime = `${startStopData?.timeHour} : ${startStopData?.timeMin}`;
        // const duration_consumed = startStopData?.duration_consumed;

        // const totalTimeInSeconds = parseInt(totalTime.split(":")[0]) * 3600 + parseInt(totalTime.split(":")[1]) * 60;

        // if (duration_consumed >= totalTimeInSeconds) {
        //     handlePauseSession();
        //     return history.push(`/courseAdministration/coursesdetails/${id}/assessments/open/assigned/assessmentMarking/${subId}`);
        // }

        // if (startStopData?.is_start_assessment == 1) {
        //     if (startStopData?.timeLimitActive) {
        //         if (startStopData?.timeHour == 0 && startStopData?.timeMin == 0) {
        //             setTimeLeft(0);
        //         } else if (startStopData?.duration_consumed > 0) {
        //             setTimeLeft(((startStopData?.timeHour || 0) * 60 * 60 + (startStopData?.timeMin || 0) * 60 + 0) - startStopData?.duration_consumed);
        //         } else {
        //             setTimeLeft((startStopData?.timeHour || 0) * 60 * 60 + (startStopData?.timeMin || 0) * 60 + 0);
        //         }
        //     }
        // }

        if(startStopData?.is_start_assessment == 1 && startStopData?.timeLimitActive) {
            
            // const totalTime = ((startStopData?.timeHour || 0) * 3600 + (startStopData?.timeMin || 0) * 60 + 0);

            setTimeLeft(startStopData?.duration_consumed);
        

            if (startStopData?.is_start_assessment == 1) {
                const intervalId = setInterval(() => {
                    setTimeLeft(prevTime => {
                        // if (startStopData?.duration_consumed >= totalTime) {
                        //    clearInterval(intervalId);
                        //    return startStopData?.duration_consumed;
                        // }
                        return prevTime + 1;
                    });
                }, 1000);
            }
        }
    }, [openTabs, startStopData]);

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secs = seconds % 60;

        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    const date = new Date(openTabs == "assessmentMarking" ? practicalAssessmentHeader?.lecturer_assessed_finalize_mark_date * 1000 : practicalAssessmentHeader?.self_assessed_finalize_mark_date * 1000);

    const formattedDate = `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;



    return (
        <>
            {openTabs == "assessmentMarking" && <div className='card-new card card-profile-info-card d-flex flex-row align-items-center practical-time-limit assessment mt-0' >
                <div style={{ marginRight: 10 }}>
                    <i className="fal fa-clock clock-icon"></i>
                </div>
                <div className='assessment_text'>
                    Time Limit: <strong style={{ color: "#EF0000" }}>{formatTime(timeLeft)}</strong>
                </div>
            </div >}

            <div className="card-new card card-profile-info-card mb-30 mt-0 padding-20" style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}>
                    {/* <div className="card-header">{`${headerData?.ass_type == 13 || headerData?.ass_type == 14 ? "Assessment" : "Marking"} Management`} {loading && <i className="fas fa-cog fa-spin ml-2"></i>}</div> */}
                    <div className="card-header mb-0">{subType == "questionDetails" ? quizQuestion?.question_name : "Practical Assessment Details"}</div>
                    {subType == undefined && <div className="card-body-inr card-body-info" style={{ paddingTop: 10 }}>
                        <div className="basic-info-box">
                            <div className="row">
                                    <>
                                        <div className='col-sm-6 col-md-4 col-lg-2 mb-md-0 mb-3'>
                                            <div className="basic-info-box-list">
                                                <ul>
                                                    <li>
                                                        <span className="title" title="Assessment Status">Assessment Status</span>
                                                    </li>
                                                    <li>
                                                        <span className="title" style={{ lineHeight: "18.62px" }} title={practicalAssessmentHeader?.status}>{RenderSubmissionAndResultStatus(practicalAssessmentHeader?.status, "programme")?.html}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className='col-sm-6 col-md-4 col-lg-2 mb-md-0 mb-3'>
                                            <div className="basic-info-box-list">
                                                <ul>
                                                    <li>
                                                        <span className="title" title="Date Completed">Date Completed</span>
                                                    </li>
                                                    <li>
                                                        <span className="title" title={ openTabs == "assessmentMarking" ? practicalAssessmentHeader?.lecturer_assessed_finalize_mark_date ? formattedDate : "-" : practicalAssessmentHeader?.self_assessed_finalize_mark_date ? formattedDate : "-"}>{openTabs == "assessmentMarking" ? practicalAssessmentHeader?.lecturer_assessed_finalize_mark_date ? formattedDate : "-" : practicalAssessmentHeader?.self_assessed_finalize_mark_date ? formattedDate : "-"}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <div className='col-sm-6 col-md-4 col-lg-2 mb-md-0 mb-3'>
                                            <div className="basic-info-box-list">
                                                <ul>
                                                    <li>
                                                        <span className="title" title="Time">Time</span>
                                                    </li>
                                                    <li>
                                                        <span className="title" title={`${practicalAssessmentHeader?.timeHour}H : ${practicalAssessmentHeader?.timeMin}M`}>{`${practicalAssessmentHeader?.timeHour}H : ${practicalAssessmentHeader?.timeMin}M`}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                        <div className='col-sm-6 col-md-4 col-lg-2 mb-md-0 mb-3'>
                                            <div className="basic-info-box-list">
                                                <ul>
                                                    <li>
                                                        <span className="title" title="Assessment Method">Assessment Method</span>
                                                    </li>
                                                    <li>
                                                        <span className="title" style={{ lineHeight: "18.62px" }} title={practicalAssessmentHeader?.assessment_method}>{RenderCategoriesType(practicalAssessmentHeader?.assessment_method, "programme")?.html}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <div className='col-sm-6 col-md-4 col-lg-2 mb-md-0 mb-3'>
                                            <div className="basic-info-box-list">
                                                <ul>
                                                    <li>
                                                        <span className="title" title="Criteria">Criteria</span>
                                                    </li>
                                                    <li>
                                                        <span className="title" title={practicalAssessmentHeader?.total_criteria_score}>{practicalAssessmentHeader?.total_criteria_score}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <div className='col-sm-6 col-md-4 col-lg-2'>
                                            <div className="basic-info-box-list">
                                                <ul>
                                                    <li>
                                                        <span className="title" title="Students">Students</span>
                                                    </li>
                                                    <li>
                                                        <span className="title" title={practicalAssessmentHeader?.totalStudents}>{practicalAssessmentHeader?.totalStudents}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                            </div>
                        </div>
                    </div>}

                    {subType == "questionDetails" ? <div className={`description-container fontsize-16 ${subType == "questionDetails" && "mb-3"}`}>
                        <p
                            ref={descriptionRef}
                            style={{ paddingTop: 10 }}
                            className={`description-p ${expanded ? 'expanded' : 'collapsed'}`}
                            dangerouslySetInnerHTML={{ __html: description }}
                        />

                        {showSeeMore && (
                            <span onClick={handleToggle} className="toggle-button">
                                {expanded ? 'See Less' : 'See More'}
                            </span>
                        )}
                    </div> : practicalAssessmentHeader?.details ? <div className={`description-container`}>
                        <p
                            ref={descriptionRef}
                            style={{ paddingTop: 10 }}
                            className={`description-p ${expanded ? 'expanded' : 'collapsed'}`}
                            dangerouslySetInnerHTML={{ __html: description }}
                        />
                    </div> : null}

                    {subType == "questionDetails" && <div className="row">
                        {(quizQuestion?.evidence_photo == 1 || quizQuestion?.evidence_video == 1 || quizQuestion?.evidence_document == 1 || quizQuestion?.evidence_lecturer_observation == 1) && <div className='col-xl-5 col-lg-6'>
                            <h6 className='fontsize-14 mb-4 fw-bolds' title="Evidence Required">Evidence Required</h6>
                            <div className='d-flex flex-wrap fontsize-16'>
                                {quizQuestion?.evidence_photo == 1 && <div className='mr-3'>
                                    <span title="Photo"><i className="fal fa-camera"></i> Photo</span>
                                </div>}
                                {quizQuestion?.evidence_video == 1 && <div className='mr-3'>
                                    <span title="Video"><i className="fal fa-video"></i> Video</span>
                                </div>}
                                {quizQuestion?.evidence_document == 1 && <div className='mr-3'>
                                    <span title="Document"><i className="fal fa-file-alt"></i> Document</span>
                                </div>}
                                {quizQuestion?.evidence_lecturer_observation == 1 && <div className='lecturer-observation-question'>
                                    <span title="Lecturer Observation"><i className="fal fa-user-check"></i> Lecturer Observation</span>
                                </div>}
                            </div>
                        </div>}

                        <div className='col-xl-4 col-lg-6 mt-3 mt-lg-0'>
                            <h6 className='fontsize-14 mb-4 fw-bolds' title="Students">Students</h6>
                            <div className='d-flex flex-wrap fontsize-12 fw-bolds'>
                                <div className='mr-3' title={`Not Yet Marked: ${quizQuestion?.not_marked ? quizQuestion?.not_marked : 0}`}>
                                    <span>{`Not Yet Marked: ${quizQuestion?.not_marked ? quizQuestion?.not_marked : 0}`}</span>
                                </div>
                                <div className='mr-3' title={`Marked: ${quizQuestion?.marked ? quizQuestion?.marked : 0}`}>
                                    <span>{`Marked: ${quizQuestion?.marked ? quizQuestion?.marked : 0}`}</span>
                                </div>
                                <div title={`Absent: ${quizQuestion?.absent ? quizQuestion?.absent : 0}`}>
                                    <span>{`Absent: ${quizQuestion?.absent ? quizQuestion?.absent : 0}`}</span>
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 col-lg-6 mt-3 mt-xl-0">
                            <h6 className='fontsize-14 mb-4 fw-bolds' title="Criteria Score">Criteria Score</h6>
                            <p className='mb-0' title={quizQuestion?.correct_score ? quizQuestion?.correct_score : 0}>{quizQuestion?.correct_score ? quizQuestion?.correct_score : 0}</p>
                        </div>
                    </div>}
            </div>
        </>
    )
}

export default AssessmentDetails